import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClassType, CourseModule, DurationList, FileObj } from 'src/app/models/course-interface';
import { AddModuleComponent } from 'src/app/components/create-course/create-modules/add-module/add-module.component';
import { SuccessModalComponent } from '../../global-modal/global-modal.component';
import { PreviewPdfComponent } from './preview-pdf/preview-pdf.component';
import { Subject, debounceTime, filter } from 'rxjs';
import { EditModuleComponent } from './edit-module/edit-module.component';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CourseService } from 'src/app/services/course.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-create-modules',
  templateUrl: './create-modules.component.html',
  styleUrls: ['./create-modules.component.scss'],
})
export class CreateModulesComponent {
  constructor(private courseService: CourseService, private modalService: NgbModal, private toastr: ToastrService, private apiService: ApiService) { }

  @Input() courseId: string = '';
  pageSize: number = 5;
  currentPage: number = 1;
  listModules: CourseModule[] = [];
  viewPdfFile: string = '';
  classType: ClassType[] = [];
  showBy: number = 10;
  originalModules: CourseModule[] = [];
  searchModuleInput$ = new Subject<void>();
  searchModule: string = ''

  get totalPages(): number {
    return Math.ceil(this.listModules.length / this.pageSize);
  }

  ngOnInit() {

    this.getClassType();
    this.getModuleList();

    this.searchModuleInput$
    .pipe(debounceTime(1000))
    .subscribe(() => {
      // Call the search function after debounce time
      this.performSearch();
    });

  }

  onSearchInputChange(): void {
    // Emit value to the searchCourseInput$ subject whenever the input changes
    this.searchModuleInput$.next();
  }

  performSearch(): void {
    this.listModules = this.originalModules.filter(item => item.cm_title.toLowerCase().includes(this.searchModule.toLowerCase()))
  }

  getClassType() {
    this.courseService.getCourseTrainingCategory('class_type').subscribe(res => {
      
      const newList = res.filter((item: ClassType) => item.ct_display_name !== 'Class 1')
      this.classType = newList;
    });
  }


  getModuleList() {
    this.courseService.getCourseModuleList(this.courseId).subscribe(res => {
      this.listModules = res
      if (res != null) {
      this.listModules = this.listModules.map((item: CourseModule) => ({
        ...item,
        class_type: this.classType.filter((val: ClassType) => val._id == item.cm_class_type).length != 0? this.classType.filter((val: ClassType) => val._id == item.cm_class_type)[0].ct_display_name : 'no class',
      }));
       
        this.originalModules = _.cloneDeep(res)
      } else {
        this.listModules = [];
      }
     
    });
  }


  isConfirmDelete(id: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'custom-modal-width'
    })

    modalRef.componentInstance.isDelete = true;
    modalRef.componentInstance.title = 'Are you sure?';
    modalRef.componentInstance.message = 'Are you sure to delete this module?';

    modalRef.componentInstance.closeModal.subscribe((val: any) => {

      if (val) {
        this.removeModule(id);  
      }

      this.modalService.dismissAll();
    })

  }

  editModule(currModule: CourseModule, courseModuleId: String) {
    const modalRef = this.modalService.open(EditModuleComponent, {
      centered: true,
      backdrop: 'static',
    });


    // modalRef.componentInstance.courseId = this.courseId;
    modalRef.componentInstance.moduleObj = currModule;
    console.log('id module sini', courseModuleId)
    modalRef.componentInstance.courseModuleId = courseModuleId;
    modalRef.componentInstance.classType = this.classType;
    modalRef.componentInstance.closeModal.subscribe(() => {
      console.log('course idddd', this.courseId)
      this.getModuleList();
    })
  }

  removeModule(id: string) {
    this.courseService.deleteModule(id).subscribe(res => {
      this.toastr.success('Successfully delete mdoule', 'Success', {
        timeOut: 3000,
        progressBar: true,
      });
      this.getModuleList()
    })
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  addNewModule() {
    const modalRef = this.modalService.open(AddModuleComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.courseId = this.courseId;
    modalRef.componentInstance.classType = this.classType;

    modalRef.componentInstance.closeModal.subscribe(() => {
      this.getModuleList();
    })


  }

  viewPdf(item: FileObj) {
    console.log('>> file', item)

    this.apiService.getPdfFile(item.f_path).subscribe(res => {
      console.log('>> url', res.url);
      // this.viewPdfFile = res.url

      const viewPdf = this.modalService.open(PreviewPdfComponent, {
        centered: true,
        backdrop: 'static',
        windowClass: 'pdf-modal-width'
      })

      viewPdf.componentInstance.pdfUrl = res.url
      viewPdf.componentInstance.pdfName = item.f_path.split('/')[1]
      viewPdf.componentInstance.closeModal.subscribe(() => {
        console.log('Close modal event received');
        viewPdf.close();
      }, (error: Error) => {
        console.error('Error in closeModal subscription:', error);
      }, () => {
        console.log('closeModal subscription completed');
      });


    })
  }
}
