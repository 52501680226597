import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SuccessModalComponent } from '../../global-modal/global-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from 'src/app/services/booking.service';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-price-summary',
  templateUrl: './price-summary.component.html',
  styleUrls: ['./price-summary.component.scss']
})

export class PriceSummaryComponent {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() totalPrices: number = 0;
  @Input() paymentUrl: string | null = '';
  @Input() paymentId: string = ''
  discount_input: number = 0;

  constructor(private router: Router, private modalRef: NgbModal, private bookingApiService: BookingService) {}

  navigatePayment(paymentUrl: string) {
    console.log('>> payment url here', paymentUrl)
    // this.router.navigate([paymentUrl]);
    window.location.replace(paymentUrl)

  }

  checkoutCart() {
    this.bookingApiService.checkoutPayment(this.paymentId).pipe(
     catchError(error => {
        console.error('>> nad Error hereeee:', error);
      return of(null)
      })
    ).subscribe(
      res => {
        // Handle the response here
        console.log('Payment check result:', res);
        this.navigatePayment(res);
      }
    )
  }

  closeModals() {
    this.closeModal.emit();
  }

}
