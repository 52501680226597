<section class="h-100 gradient-form" style="background-color: #eee;">

  <div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-xl-12">
      <div class="card rounded-3 text-black">
        <div class="row g-0">
          <div class="col-lg-5">
            <div class="card-body p-md-5 mx-md-4">
              <img src="../../assets/img/logo-afi.png" class="logo" />

              <p class="login-txt">Welcome</p>
              <span>Log in to your account</span>
              <!-- Your existing HTML code for the login form -->
              <form (ngSubmit)="login()" [formGroup]="form">
                <div class="form-outline mb-4 d-flex flex-column form-group">
                  <label class="form-label" for="form2Example11">Email</label>
                  <input type="email" id="form2Example11" class="login-input" placeholder="Enter Email"
                    [(ngModel)]="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                      <div *ngIf="f['email'].errors['required']">Email is required</div>
                      <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                    </div>
                </div>

                <div class="form-outline mb-4 form-group">
                  <label class="form-label">Password</label>
                  <div class="password-row">
                    <div class="password-bar">
                      <input class="login-input" [(ngModel)]="password" name="password"
                        [type]="isShowPassword ? 'text' : 'password'" placeholder="Enter Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                        <img class="password-icon" src="../../../assets/icon/password.svg" alt="password-icon"
                        (click)="isShowPassword = !isShowPassword" />
                    </div>
                  </div>
                  <div *ngIf="submitted && f['password'].errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f['password'].errors['required']">Password is required</div>
                    <div *ngIf="f['password'].errors['minlength']">
                      Password must be at least 6 characters
                    </div>
                    </div>
                
                </div>

                <div class="col-12">
                  <button type="submit" class="btn btn-login">Login</button>
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <span>New user? <span style="font-weight: bold; cursor: pointer;"
                      [routerLink]="'/signup'">Register</span></span>
                </div>

              </form>

            </div>
          </div>

          <div class="col-lg-7">
            <div class="bg"></div>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>