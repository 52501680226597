import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/services/permission.service';
import { filter, startWith } from 'rxjs/operators';
import { User, UserAccess } from 'src/app/models/user';
import { USER_PERMISSIONS } from 'src/app/permission-const';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  USER_PERMISSION = USER_PERMISSIONS
  routerVar: Subscription | undefined;
  role: string = '';


  constructor(private router: Router) {}


  ngOnDestroy() {
    this.routerVar?.unsubscribe();

   
  }

  ngOnInit() {

    this.routerVar = this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: any) => {
      const isRouterLink = event.navigationTrigger === 'imperative';
      localStorage.setItem('isRouterLink', isRouterLink.toString());
    });

    this.role = localStorage.getItem('role')!
    console.log('>> roles', localStorage.getItem('role'))
  }
  

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
}
