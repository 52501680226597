<div>
  <form (ngSubmit)="saveModule()" [formGroup]="moduleForm">
    <div class="container">
        <div class="module-header">
            <span>Edit Module</span>
            <img src="../../../assets/icon/close.svg" alt="close-module" (click)="closeModals()" style="cursor: pointer;">
        </div>
        
        <div class="module-content mt-1 mb-2">
            <span class="desc"> Please fill in the module details as provided below. </span>
            <div class="form-module">
                <div class="w-100">
                    <label class="w-100">Title <span>*</span></label>
                    <input formControlName="cm_title" [ngClass]="{ 'is-invalid': submitted && f['cm_title'].errors }" type="text" class="input" [(ngModel)]="modulePost.cm_title" placeholder="Please enter title" />
                    <div *ngIf="submitted && f['cm_title'].errors" class="invalid-feedback">
                      <div *ngIf="f['cm_title'].errors['required']">Title is required</div>
                    </div>
                  </div>
                <div class="w-100">
                    <label class="w-100">Description</label>
                    <textarea type="text" class="input" [(ngModel)]="modulePost.cm_description" formControlName="cm_description" [ngClass]="{ 'is-invalid': submitted && f['cm_description'].errors }"  
                        placeholder="Please enter description"></textarea>
                        <div *ngIf="submitted && f['cm_description'].errors" class="invalid-feedback">
                          <div *ngIf="f['cm_description'].errors['required']">Description is required</div>
                        </div>
                </div>
                <div class="d-flex w-100" style="gap: 8px">
                    <div class="w-50">
                        <label class="w-100">Class Type <span>*</span></label>
                        <select class="form-select w-100" aria-label="Course Category" formControlName="cm_class_type" [ngClass]="{ 'is-invalid': submitted && f['cm_class_type'].errors }"
                            [(ngModel)]="modulePost.cm_class_type" placeholder="Choose Category">
                            <option [value]="item._id" *ngFor="let item of classType">{{ item.ct_display_name }}</option>
                        </select>
                        <div *ngIf="submitted && f['cm_class_type'].errors" class="invalid-feedback">
                          <div *ngIf="f['cm_class_type'].errors['required']">Class type is required</div>
                        </div>
                    </div>
                    <div class="w-50">
                        <label class="w-100">Duration (minutes) <span>*</span></label>
                        <div class="input-container">
                        <input type="text" class="input" [(ngModel)]="modulePost.cm_duration"  formControlName="cm_duration" [ngClass]="{ 'is-invalid': submitted && f['cm_duration'].errors }" />
                        <div class="icons">
                            <img src="../../../../assets/icon/minus.svg" (click)="+modulePost.cm_duration > 0 ? +modulePost.cm_duration = +modulePost.cm_duration - 30 : null">
                            <div class="border"></div>
                            <img src="../../../../assets/icon/add-icon.svg" (click)="+modulePost.cm_duration = +modulePost.cm_duration + 30">
                          </div>
                          </div>
                          <div *ngIf="submitted && f['cm_duration'].errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f['cm_duration'].errors['required']">Duration is required</div>
                          </div>
                    </div>
                </div>
                <div class="upload" (click)="fileInput.click()">
                    <span style="color: #161616; font-size: 12px;">Upload Files</span>
                    <span style="color: #A8A8A8; font-size: 12px;">Max file size is 256MB. Supported file types are .doc, .docx, .pdf, .ppt, .pptx, .png, .txt, .xls and .xlsx.</span>
                    <div class="cont">
                      <img src="../../../../assets/icon/upload-module.svg" style="width: 100px; height: 100px;" alt="upload-module">
                      <input type="file" #fileInput style="display: none;" accept=".pdf" multiple (change)="handleFileHere($event)">
                    </div>
                  </div>
                  <div class="file-container" *ngFor="let file of modulePost.files; let i = index">
                    <div class="file-item">
                      <div class="file-icon">
                        <img src="../../../../assets/icon/pdf.svg" style="width: 28px; height: 28px;">
                      </div>
                      <div class="file-details">
                        <div class="d-flex">
                          <div style="font-size: 12px;" *ngIf="file.fileName">{{ file.fileName }} <img src="../../../../assets/icon/bullet.svg" style="width: 4px; height: 4px;" alt="upload-module"></div>
                          <div *ngIf="file.progress < 100" class="preview-text">Uploading...</div>
                          <div *ngIf="file.progress === 100" class="preview-text" (click)="viewPdf(i)">Preview</div>
                        </div>
                        <div *ngIf="file.progress < 100" class="progress">
                          <div class="progress-bar" [style.width.%]="file.progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- Preview file name -->

                  <div class="file-container" *ngFor="let file of modulePost.cm_files;">
                    <div class="file-item">
                      <div class="file-icon">
                        <img src="../../../../assets/icon/pdf.svg" style="width: 28px; height: 28px;">
                      </div>
                      <div class="file-details">
                        <div class="d-flex" style="font-size: 12px;">
                                <span style="color:black !important">
                                  {{ file.f_path.split('-').pop() }}
                                </span>
                            <span *ngIf="modulePost.cm_files.length === 0" style="color:black !important">
                                No file to preview
                            </span>
                            <img *ngIf="modulePost.cm_files.length > 0" src="../../../../assets/icon/bullet.svg" style="width: 4px; height: 4px; margin-top: 8px; margin-left: 3px;" alt="upload-module">
                          
                          <div *ngIf="modulePost.cm_files.length > 0">
                            <div class="preview-text" *ngFor="let item of modulePost.cm_files" (click)="viewExistingPDF(item)">
                                Preview
                              </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- End Preview file name -->
                
                <div class="w-100">
                    <label class="w-100">URL</label>
                    <input type="text" class="input" [(ngModel)]="modulePost.cm_url" placeholder="Eg: https://luma.humatheme.com/Demo-course.html" formControlName="cm_url" name="cm_url"/>
                    <span style="color: #A8A8A8; font-size: 12px;">Enter a valid video URL.</span>
                </div>
                    <div class="w-100">
                    <input type="checkbox" id="access" name="cm_allow_access" [(ngModel)]="modulePost.cm_allow_access" formControlName="cm_allow_access"/>
                    <label for="access" class="access">Allow access</label><br>
                </div>
            </div>
    
        </div>
    </div>
  
    <div class="module-footer w-100">
        <div class="cancel w-50" (click)="closeModals()">Cancel</div>
        <button class="confirm w-50" type="submit">Save</button>
    </div>
  </form>
</div>