<div class="">
    <div #listGrid class="list-catalog" *ngIf="(jsonData && jsonData.length != 0) && currentCourseId == null">
        <div class="col-3 mt-2" *ngFor="let module of jsonData" style="position: relative;">
            <div class="label"></div>
            <div class="card" (mouseleave)="currentId = ''">
                <div class="overlay" *ngIf="currentId == module._id"></div>
                <div class="button-overlay d-flex flex-column align-items-start" *ngIf="currentId == module._id">
                    <!-- <div class="btn btn-register">Register</div> -->
                    <div class="btn btn-preview mt-auto d-flex align-items-center" style="gap: 10px; width: 162px;" (click)="onCardClick(module._id)">Learn More <img src="../../../assets/icon/learn-more.svg" alt="learn-more" style="width: 20px; height: 20px;"></div>
                    
                </div>
                <!-- MODULE CODE -->
                <div class="course-name">
                    <div class="course-label">
                        {{ module.c_course_name}}
                    </div>
                </div>
                <div class="hdrc" *ngIf="module.c_hrdc_claimable">
                    <div class="hdrc-label" style="color: #FFF; font-size: 12px;">
                        HRDC
                    </div>
                </div>
                <!-- tagged -->
                
                <img src="../../../assets/img/category_img.png" class="card-img-top" alt="Card Image" (mouseenter)="currentId = module._id;">
                
                <div class="card-body">
                    <p class="module-id">{{module.c_course_id}}</p>
                    <p class="desc">
                        {{module.c_course_description == '' ? 'No Description' : module.c_course_description}}
                    </p>
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="price">
                                RM {{module.c_price_per_pax}} / pax
                            </div>
                            <div class="price" style="font-weight: 400;"> {{ module.c_course_duration  }} {{module.c_course_duration < 2 ? 'Day': 'Days' }}</div>
                        </div>
                        <div (click)="updateCourse(module.is_fav, module._id)" style="cursor: pointer;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                                <path d="M11.9878 3.593C12.6628 2.43403 14.3372 2.43403 15.0122 3.593L17.2047 7.3573C17.5226 7.90316 18.0554 8.29022 18.6728 8.42391L22.9304 9.34588C24.2412 9.62973 24.7586 11.2221 23.8649 12.2222L20.9624 15.4707C20.5415 15.9417 20.338 16.568 20.4016 17.1965L20.8405 21.5306C20.9756 22.865 19.621 23.8491 18.3937 23.3083L14.4073 21.5516C13.8293 21.2969 13.1707 21.2969 12.5927 21.5516L8.60631 23.3083C7.37897 23.8491 6.02442 22.865 6.15953 21.5306L6.59835 17.1965C6.66199 16.568 6.4585 15.9417 6.0376 15.4707L3.13506 12.2222C2.24142 11.2221 2.75881 9.62973 4.06965 9.34588L8.32724 8.42391C8.94462 8.29022 9.47737 7.90316 9.7953 7.3573L11.9878 3.593Z" 
                                [attr.fill]="module.is_fav ? '#DDFF55' : 'white'" [attr.stroke]="module.c_is_favourite ? 'grey' : '#161616' " stroke-width="0.5"/>
                              </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="jsonData && jsonData.length == 0">
        <span style="text-align: center; font-style: italic;"> No courses registered yet.</span>
    </div>
</div>
