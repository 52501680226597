import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
 userPermissionsSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  userPermissions$: Observable<any> = this.userPermissionsSubject.asObservable();


  // Use EventEmitter to notify components about permission changes
  permissionsUpdated: EventEmitter<any> = new EventEmitter<any>();
  newUser = [] as any
  setUserPermissions(user: any): void {
  
    if (user) {
      console.log('>>> permission here: 1', user)
      this.userPermissionsSubject.next(user);
    
      
      // Emit the event to notify components about permission changes
      console.log('>>> permission here: ', this.userPermissionsSubject.value)
      this.permissionsUpdated.emit(user);
    } else {
      console.error('Error: userPermissions is null');
    }
  }

  getUserPermissions() {
    return this.userPermissionsSubject.value;
    // return this.userPermissions$;
  }

  isGranted(permission: any) {
    const storedUserData = localStorage.getItem('currentUser');

    if (storedUserData) {
      const allPermissions = JSON.parse(storedUserData).u_role_id.r_access;
      const permissionFound = allPermissions[permission.split('.')[0]][permission.split('.')[1]];
      return permissionFound;
    } else {
      return null;
    }
    // const userPermissions = this.getUserPermissions();      
    // console.log('>>> sini user permissions: ', userPermissions)
    // const permissionFound = userPermissions.some((item: string) => permission.includes(item))? true : false;
    // return permissionFound;
    // return true
  }
  hasPermission(module: string, action: string): boolean {
    const userPermissions = this.userPermissionsSubject.value;
    return userPermissions?.[module]?.[action] || false;
  }



  // Utility function to extract nested properties
  private extractNestedProperty(obj: any, path: string): any {
    const properties = path.split('.');
    let currentObject = obj;
  
    for (const property of properties) {
      if (currentObject && currentObject.hasOwnProperty(property)) {
        currentObject = currentObject[property];
  
        // Log each step of the extraction process
        console.log(`Extracting property '${property}':`, currentObject);
      } else {
        // If the property is not found, handle the case gracefully
        console.error(`Error: Property '${property}' not found in the object`);
        return null;
      }
    }
  
    return currentObject;
  }
  
}
