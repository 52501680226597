import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent {
  @Input() modalRef!: NgbModalRef;
  @Input() pdfUrl = '';
  @Input() pdfName = '';
  @Output() closeModal = new EventEmitter<void>();

  constructor(private modalService: NgbModal) {}

  closeModals() {
    this.modalRef.close();
    this.closeModal.emit();
  }
}
