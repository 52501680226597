import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './components/home/home.component';
import { CreateModulesComponent } from './components/create-course/create-modules/create-modules.component';
import { OrderDetailsComponent } from './components/booking-course/order-details/order-details.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { SuccessModalComponent } from './components/global-modal/global-modal.component';
import { CheckoutListComponent } from './components/booking-course/checkout-list/checkout-list.component';
import { PriceSummaryComponent } from './components/booking-course/price-summary/price-summary.component';
import { PaymentPageComponent } from './components/booking-course/payment-page/payment-page.component';
import { ScheduleComponent } from './components/schedule/schedule.component'; // Import the new Schedule component
import { AuthGuard } from './auth.guard';
import { CheckoutPageComponent } from './components/checkout-page/checkout-page.component';
import { SuccessPaymentComponent } from './components/success-payment/success-payment.component';
import { BookingSessionListNewComponent } from './components/booking-course/booking-session-list-new/booking-session-list-new/booking-session-list-new.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: SignupComponent },
  { path: 'pages', loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule), canActivate: [AuthGuard] },
  { path: 'createModules', component: CreateModulesComponent },
  { path: 'payment/review', component: OrderDetailsComponent },
  { path: 'loading', component: LoadingPageComponent },
  { path: 'success', component: SuccessModalComponent },
  { path: 'booking/cart', component: CheckoutListComponent },
  { path: 'priceSummary', component: PriceSummaryComponent },
  { path: 'payment', component: PaymentPageComponent },
  { path: 'session', component: ScheduleComponent },
  { path: 'checkout/:sessionId', component: CheckoutPageComponent },
  { path: 'success_payment', component: SuccessPaymentComponent },
  { path: 'booking-session/:id', component: BookingSessionListNewComponent },

  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
