       <!-- <div class="overlay">
       </div> -->
            <div style="padding: 20px;">
                <div class="flex frame">
                        <div>
                            <div class="card-1">
                            </div>
                        </div>
                        <div class="card-2">
                        </div>
                    <div class="flex card-container" style="gap: 40px;">
                            <div class="flex card-item-1">
                                    <div class="card-3">
                                    </div> 
                                    <div class="card-4">
                                    </div> 
                            </div>
                        <div class="flex card-container" style="gap: 12px;">
                                        <div class="card-5">
                                        </div> 
                                        <div class="flex card-item-2">
                                            <div class="card-6">
                                            </div>

                                            <div class="card-6">
                                            </div>

                                            <div class="card-6">
                                            </div>

                                            <div class="card-6">
                                            </div>
                                        </div>
                        </div>
                        <div class="flex card-container">
                                <div class="card-7">
                                </div>
                        </div>
                    </div>
                </div>
            </div>
       
       <img src="../../assets/gif/loading.gif" class="loading-image" />
  