import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-mini-calendar',
  templateUrl: './mini-calendar.component.html',
  styleUrls: ['./mini-calendar.component.scss']
})
export class MiniCalendarComponent {
  selectedDate: { day?: number, month: number, year: number } | null = null;
  @Output() selectedMonthYear: EventEmitter<string> = new EventEmitter<string>()
  currentDate: Date = new Date();
  calendar: number[][] = [];
  weekDays: string[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  monthNames: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  @Input() dateChanged: any =  new Date()

  ngOnChanges(changes: SimpleChanges) {

    if (changes['dateChanged']) {

      if (this.dateChanged.getMonth() == new Date().getMonth()) {
        this.currentDate = new Date()
        this.selectedDate = {
          day: new Date().getDate(),
          month: this.currentDate.getMonth(),
          year: this.currentDate.getFullYear()
        };
      } else {
        this.currentDate = this.dateChanged
      }
      this.generateCalendar()
     
    }

  }

  ngOnInit(): void {
    this.selectedDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth(),
      year: this.currentDate.getFullYear()
    };
    this.generateCalendar();
  }

  generateCalendar(): void {
    const startOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const endOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);

    const startDay = startOfMonth.getUTCDay();
    const endDay = endOfMonth.getDate();
    let day = 1;
    this.calendar = [];

    for (let i = 0; i < 6; i++) { // 6 rows (weeks)
      const week: any = [];
      for (let j = 0; j < 7; j++) { // 7 columns (days)
        if ((i === 0 && j < startDay) || day > endDay) {
          week.push(null);
        } else {
          week.push(day++);
        }
      }
      this.calendar.push(week);
    }
  }

  isSelected(day: number | null): boolean {
    if (!day || !this.selectedDate) return false;
    return (
      day === this.selectedDate.day &&
      this.currentDate.getMonth() === this.selectedDate.month &&
      this.currentDate.getFullYear() === this.selectedDate.year
    );
  }

  prevMonth(): void {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
    this.generateCalendar();
    if (this.currentDate.getMonth() == new Date().getMonth()) {
      this.selectedDate = {
        day: new Date().getDate(),
        month: this.currentDate.getMonth(),
        year: this.currentDate.getFullYear()
      };
    }
  }

  nextMonth(): void {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
    this.generateCalendar();

    if (this.currentDate.getMonth() == new Date().getMonth()) {
      this.selectedDate = {
        day: new Date().getDate(),
        month: this.currentDate.getMonth(),
        year: this.currentDate.getFullYear()
      };
    }
   
  }

  selectDay(month: any, day: number) {
    this.selectedMonthYear.emit(month)
    if (!day) return;
    this.selectedDate = {
      day: this.currentDate.getMonth() == new Date().getMonth() ? day : 0,
      month: this.currentDate.getMonth(),
      year: this.currentDate.getFullYear()
    };
  }
}
