import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Environment } from '../environments/environment';
import { CheckPayment } from '../models/booking';

interface URLS {
  session_id: string;
  success_url: string;
  cancel_url: string;
}

interface DeletePayment {
  stripeId: string;
  paymentId: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private baseUrl = Environment.apiUrl;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

   currentToken(): any {
    const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`
  };
  }

  createSession(session_id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart`;
    return this.http.post(url, {session_id}, { headers }).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        if (error.error.statusCode == 409) {
          this.toastr.error(error.error.message, 'Error', {
            timeOut: 3000,
            progressBar: true,
          });
        }
        return throwError(error.error);
      })
    )
  }

  getCartList(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  checkoutPayment(id: string): Observable<any> {
    const headers = this.currentToken()!
    const data: URLS = {
      session_id: id,
      success_url: `${Environment.frontendUrl}/payment/review`,
      cancel_url: `${Environment.frontendUrl}/payment/history`,
    }
    const url = `${this.baseUrl}/cart/payment`;
    return this.http.post(url, data, { headers });
  }

  checkPayment(data: CheckPayment): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart/payment/check`;
    return this.http.post(url, data, { headers });
  }

  getPaymentDetails(paymentId: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart/payment/details/${paymentId}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching payment details:', error);
        return throwError(error);
      })
    )
  }

  getPaymentHistory(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart/payment/history`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching payment history:', error);
        return throwError(error);
      })
    )
  }
  
  removePaymentSession(data: DeletePayment): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart/payment`;
    const options = {
      headers,
      body: data
    };
    return this.http.delete(url, options).pipe(
      tap(response => {
        return response;
      })
    );
  }
  removeSession(session_id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/cart`;
    const options = {
      headers,
      body: { session_id }
    };
    return this.http.delete(url, options).pipe(
      tap(response => {
        return response;
      })
    );
  }

  
}
