

<div class="calendar-container">
    <full-calendar #fullCalendar [options]="calendarOptions" [style]="!isDoneLoad ? 'visibility: hidden;': 'visibility:visible;'">
      <ng-template #eventContent let-arg>
        <div style="position: relative; background: #F5F5F5 !important; border-radius: 5px; border: none !important;" [ngClass]="arg.event.extendedProps.isExpired ? 'expired-class' : ''">
          <img src="../../../assets/img/Aerodyne-calendar.JPG" class="bg-img-calendar">
          <div class="fc-event-content cyberjaya" data-session-id="${sessionId}" placement="bottom" [ngbTooltip]="arg.event.extendedProps.isExpired ? null : 'Choose this date to secure your session'"  container="body" [ngStyle]="arg.event.extendedProps.isExpired ? {'cursor': 'not-allowed'} : {}">
            <div *ngIf="!arg.event.extendedProps.isExpired && arg.event.extendedProps.emptyseatsPercent <= 50" class="event-banner">
              Hurry, only {{ arg.event.extendedProps.seats }} seats left!
            </div>
            <div *ngIf="!arg.event.extendedProps.isExpired && arg.event.extendedProps.emptyseatsPercent > 50 && arg.event.extendedProps.showLastChanceBanner" class="event-banner">
              Last Chance, Register Now!
            </div>
            <div class="fc-event-title" style="font-size: 18px; white-space: wrap;" [style]="arg.event.extendedProps.isExpired ? 'text-decoration: line-through' : ''">
              {{ arg.event.extendedProps.isExpired ? 'Soldout: ' : '' }}{{ arg.event.title }}</div>
            <div class="d-flex" style="flex-wrap: wrap;"><span style="color: #2B3C3D; font-size: 16px;">Cyberjaya, Selangor</span> &nbsp; | &nbsp;<div class="fc-event-time" style="font-weight: 400;">9:00am - 5:00pm</div></div>
            <div *ngIf="!arg.event.extendedProps.isExpired" class="fc-event-seats">{{ arg.event.extendedProps.seats }} seats available</div>
            <div *ngIf="arg.event.extendedProps.isExpired" class="fc-event-seats">No seats left</div>
          </div>
        </div>

      </ng-template>
    </full-calendar>

  </div>


