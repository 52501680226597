<nav class="col-md-2 d-none d-md-block bg-light sidebar">
    <!-- Sidebar content goes here -->
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link active" href="#">
          Dashboard
        </a>
      </li>
      <!-- Add more sidebar links as needed -->
    </ul>
  </nav>