import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SessionList } from 'src/app/models/session';
import { BookingCartList } from 'src/app/models/booking';
import { CourseList } from 'src/app/models/course-interface';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { Observable } from 'rxjs';
import { GetDelFile } from 'src/app/models/common';
@Component({
  selector: 'app-booking-session-list-new',
  templateUrl: './booking-session-list-new.component.html',
  styleUrls: ['./booking-session-list-new.component.scss'],
})
export class BookingSessionListNewComponent
  implements OnInit, AfterViewInit, OnChanges
{
  constructor(
    public apiService: ApiService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private CommonService: CommonService
  ) {}
  goToMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`;
  items: number[] = Array(50)
    .fill(0)
    .map((_, i) => i + 1);
  getCurrentMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`;
  calendarOptions: any;
  isCaam: boolean = false;
  allDone: boolean = false;
  dateChanged: any = new Date();
  weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  currentMonthDisplay: Date | null = null;
  currentEndMonthDisplay: Date | null = null;
  isDoneLoad: boolean = false;
  allSessions: any = [];
  fullCalendarContainer!: ElementRef;
  courseId: string = '';
  selectedSessions: SessionList = {
    createdAt: '',
    s_course_id: '',
    s_deletedAt: null,
    s_end_date: '',
    s_name: '',
    s_start_date: '',
    s_status: false,
    s_venue: '',
    trainers: [],
    updatedAt: '',
    students: [],
    _id: '',
    isCheck: false,
    status: '',
  };

  cartList: BookingCartList = {
    createdAt: '',
    sessions: [],
    updatedAt: '',
    user: '',
    _id: '',
  };

  courseDetails: CourseList = {
    _id: '',
    c_course_id: '',
    c_course_name: '',
    c_file: '',
    c_course_description: '',
    c_course_category: null,
    c_training_method: null,
    c_course_duration: 0,
    courseModules: [],
    sessions: [],
    c_is_recommended: false,
    c_is_favourite: false,
    c_price_per_pax: 0,
    c_status: false,
    c_deletedAt: null,
    createdAt: '',
    updatedAt: '',
    __v: 0,
    c_no_of_pax: 0,
    c_hrdc_claimable: false,
    c_caam_certified: false,
  };
  trainers: string[] = [];
  sidebarExpanded = false;
  firstListImg: string[] = [
    '../assets/landing-page/first-img/1.1.jpg',
    '../assets/landing-page/first-img/1.2.png',
    '../assets/landing-page/first-img/1.3.jpg',
    '../assets/landing-page/first-img/1.4.jpg',
    '../assets/landing-page/first-img/1.5.jpg',
    '../assets/landing-page/second-img/2.1.png',
    '../assets/landing-page/second-img/2.2.jpg',
    '../assets/landing-page/second-img/2.3.png',
    '../assets/landing-page/second-img/2.4.jpg',
    '../assets/landing-page/second-img/2.5.jpg',
    '../assets/landing-page/third-img/3.1.png',
    '../assets/landing-page/third-img/3.2.jpg',
    '../assets/landing-page/third-img/3.3.jpg',
    '../assets/landing-page/third-img/3.4.jpg',
    '../assets/landing-page/third-img/3.5.jpeg',
  ];

  secondListImg: string[] = [];

  thirdListImg: string[] = [];

  bannerLink: string = '';

  ngAfterViewInit() {}

  ngOnChanges() {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const courseId = params['id'];
      this.courseId = courseId;
      this.getDetails();
    });
  }
  getDetails() {
    this.courseService.getCourseDetails(this.courseId).subscribe((res) => {
      this.courseDetails = res;
      if (this.courseDetails.c_file) {
        const data: GetDelFile = {
          id: this.courseDetails.c_file._id,
        };
        this.CommonService.getFile(data).subscribe((res) => {
          this.bannerLink = res.url;
        });
      }
      else{
        this.bannerLink = '../assets/landing-page/banner/placeholder.avif';
      }
    });
  }
}
