import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';  // Adjust the import path as necessary

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {
  eventData: any = {};

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    const stripeId = this.route.snapshot.fragment;

    if (stripeId) {
      this.apiService.getPaymentDetails(stripeId).subscribe(
        response => {
          const data = response.data;
          console.log("api response payment details:",data);
          if (data && data.cart && data.p_payload) {
       
            
            this.eventData = {
              paymentId: data._id,
              courseName: data.cart.s_course_id.c_course_name,
              sessionName: data.cart.s_name,
              startDate: new Date(data.cart.s_start_date).toLocaleDateString(),
              endDate: new Date(data.cart.s_end_date).toLocaleDateString(),
              location: data.cart.s_venue.v_display_name, // Update if location is included in the response
              price: `RM ${data.cart.s_course_id.c_price_per_pax.toLocaleString()}`
            };
            console.log('Checkout - Event data:', this.eventData);
          } else {
            console.error('Incomplete data received from API', response);
          }
        },
        error => {
          console.error('Error fetching payment details:', error);
        }
      );
    } else {
      console.error('No stripeId found in the URL fragment');
    }
  }

  printPage() {
    window.print();
  }
}
