import { Component } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { BookingCartList } from 'src/app/models/booking';
import { SessionList } from 'src/app/models/session';
import { BookingService } from 'src/app/services/booking.service';
import { Location } from '@angular/common';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-checkout-list',
  templateUrl: './checkout-list.component.html',
  styleUrls: ['./checkout-list.component.scss']
})
export class CheckoutListComponent {

  constructor(private bookingApiService: BookingService, private courseService: CourseService, private location: Location) { }

  cartList: BookingCartList = {
    createdAt: '',
    sessions: [],
    updatedAt: '',
    user: '',
    _id: ''
  }

  ngOnInit() {
      this.getCartList()
  }

  redirectTo() {
    this.location.back()
  }

  getCartList() {
    this.bookingApiService.getCartList().subscribe(response => {
      if (response == null) {
        this.cartList = {
          createdAt: '',
          sessions: [],
          updatedAt: '',
          user: '',
          _id: ''
        }
      } else {
        const sessionRequests = response.sessions.map((item: SessionList) =>
          this.courseService.getCourseDetails(item.s_course_id).pipe(
            map(res => ({ ...item, course: res })) // Merge the course details with the session item
          )
        );

        forkJoin(sessionRequests).subscribe(updatedSessions => {
          this.cartList = { ...response, sessions: updatedSessions };
        });

      }

    })

    console.log('init cart list', this.cartList)
  }

  refreshCartList(val: boolean) {
    if (val) {
      this.getCartList()
    }
   
  }

}
