<div>
  <form (ngSubmit)="saveModule()" [formGroup]="moduleForm">
    <div class="container">
      <div class="module-header">
        <span> Add New Modules</span>
        <img src="../../../assets/icon/close.svg" alt="close-module" (click)="closeModals()" style="cursor: pointer;">
      </div>
      
      <div formArrayName="modules">
        <div class="module-content mt-1 mb-2" *ngFor="let module of modules.controls; let idx = index;" [formGroupName]="idx" style="border-radius: 8px; border: 1px solid #E0E0E0;">
          <div class="d-flex justify-content-between w-100 mt-2">
            <span>Module {{ idx + 1 }}</span>
            <span style="color: #0F62FE; cursor: pointer;" *ngIf="idx > 0" (click)="indexAddRemove('remove', idx)">Remove</span>
          </div>
          
          <span class="desc">Please fill in the module details as provided below.</span>
          <div class="form-module">
            <div class="w-100">
              <label class="w-100">Title <span>*</span></label>
              <input formControlName="cm_title" [ngClass]="{ 'is-invalid': submitted && module.get('cm_title')!.errors }" type="text" class="input" placeholder="Please enter title" />
              <div *ngIf="submitted && module.get('cm_title')!.errors" class="invalid-feedback">
                <div *ngIf="module.get('cm_title')!.errors">Title is required</div>
              </div>
            </div>
            <div class="w-100">
              <label class="w-100">Description <span>*</span></label>
              <textarea formControlName="cm_description" [ngClass]="{ 'is-invalid': submitted && module.get('cm_description')!.errors }" class="input" placeholder="Please enter description"></textarea>
              <div *ngIf="submitted && module.get('cm_description')!.errors" class="invalid-feedback">
                <div *ngIf="module.get('cm_description')!.errors">Description is required</div>
              </div>
            </div>
            <div class="d-flex w-100" style="gap: 8px">
              <div class="w-50">
                <label class="w-100">Class Type <span>*</span></label>
                <select formControlName="cm_class_type" [ngClass]="{ 'is-invalid': submitted && module.get('cm_class_type')!.errors }" class="form-select w-100" aria-label="Course Category" placeholder="Choose Category">
                  <option [value]="type._id" *ngFor="let type of classType">{{ type.ct_display_name }}</option>
                </select>
                <div *ngIf="submitted && module.get('cm_class_type')!.errors" class="invalid-feedback">
                  <div *ngIf="module.get('cm_class_type')!.errors">Class type is required</div>
                </div>
              </div>
              <div class="w-50">
                <label class="w-100">Duration (minutes) <span>*</span></label>
                <div class="input-container">
                  <input type="text" class="input" formControlName="cm_duration" [ngClass]="{ 'is-invalid': submitted && module.get('cm_duration')!.errors }" />
                  <div class="icons">
                    <img src="../../../../assets/icon/minus.svg" (click)="module.get('cm_duration')!.value > 30 ? module.get('cm_duration')!.setValue(module.get('cm_duration')!.value - 30) : null">
                    <div class="border"></div>
                    <img src="../../../../assets/icon/add-icon.svg" (click)="module.get('cm_duration')!.setValue(+module.get('cm_duration')!.value + 30)">
                  </div>
                </div>
                <div *ngIf="submitted && module.get('cm_duration')!.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="module.get('cm_duration')!.errors">Duration is required</div>
                </div>
              </div>
            </div>
            <div class="upload" (click)="fileInput.click()">
              <span style="color: #161616; font-size: 12px;">Upload Files</span>
              <span style="color: #A8A8A8; font-size: 12px;">Max file size is 256MB. Supported file types are .doc, .docx, .pdf, .ppt, .pptx, .png, .txt, .xls and .xlsx.</span>
              <div class="cont">
                <img src="../../../../assets/icon/upload-module.svg" style="width: 100px; height: 100px;" alt="upload-module">
                <input type="file" #fileInput style="display: none;" accept=".pdf" multiple (change)="handleFileHere($event, idx)">
              </div>
            </div>
            <div class="file-container" *ngFor="let file of module.get('files')!.value; let i = index">
              <div class="file-item">
                <div class="file-icon">
                  <img src="../../../../assets/icon/pdf.svg" style="width: 28px; height: 28px;">
                </div>
                <div class="file-details">
                  <div class="d-flex">
                    <div style="font-size: 12px;" *ngIf="file.fileName">{{ file.fileName }} <img src="../../../../assets/icon/bullet.svg" style="width: 4px; height: 4px;" alt="upload-module"></div>
                    <div *ngIf="file.progress < 100" class="preview-text">Uploading...</div>
                    <div *ngIf="file.progress === 100" class="preview-text" (click)="viewPdf(idx, i)">Preview</div>
                  </div>
                  <div *ngIf="file.progress < 100" class="progress">
                    <div class="progress-bar" [style.width.%]="file.progress"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100">
              <label class="w-100">URL</label>
              <input type="text" formControlName="cm_url" class="input" placeholder="Eg: https://luma.humatheme.com/Demo-course.html" />
              <span style="color: #A8A8A8; font-size: 12px;">Enter a valid video URL.</span>
            </div>
            <div class="w-100">
              <input type="checkbox" id="access" formControlName="cm_allow_access">
              <label for="access" class="access">Allow access</label><br>
            </div>
          </div>
        </div>
      </div>
      <div class="add-modules" (click)="indexAddRemove('add')">
        <i class="fa fa-add"></i> Add Modules
      </div>
    </div>
    <div class="module-footer w-100">
      <div class="cancel w-50" (click)="closeModals()">Cancel</div>
      <button class="confirm w-50" type="submit">Save</button>
    </div>
  </form>
</div>
