<app-header></app-header>
<app-left-menu
  [isExpanded]="sidebarExpanded"
  (toggleSidebar)="sidebarExpanded = !sidebarExpanded"
></app-left-menu>
<div class="content" [ngClass]="{ sidebarExpanded: sidebarExpanded }">
  <div class="header">
    <div class="img" [ngStyle]="{ 'background-image': 'url(' + bannerLink + ')' }">
      <!-- <p class="txt-1">-</p> -->
      <p class="txt-2" [innerHTML]="courseDetails.c_course_name"></p>
      <p class="txt-1">{{ courseDetails.c_course_description }}</p>
    </div>
  </div>
  <div class="container" style="padding: 0px 120px; max-width: none !important">
    <div class="row" style="margin: 20px 0px">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex flex-column" style="margin-bottom: 40px">
          <span class="head-top">Select your session date</span>
          <span style="font-size: 26px; font-weight: 400; color: #a8a8a8"
            >Choose the perfect date for your session and secure your
            spot!</span
          >
        </div>
        <!-- <div class="loading-img"></div> -->
        <div
          class="d-flex align-items-center justify-content-center"
          style="gap: 8px"
          *ngIf="isCaam"
        >
          <img
            src="../../../../assets/img/caam.png"
            alt="caam"
            style="width: 100px; height: 100px"
          />
          <span style="color: black; font-size: 24px; font-weight: 600"
            >CERTIFIED</span
          >
        </div>
      </div>
      <div class="d-flex" style="gap: 40px; position: relative">
        <img
          src="../../assets/gif/loading.gif"
          class="loading-image"
          *ngIf="!allDone"
        />
      <div class="d-flex justify-content-between w-100">
        <div class="col-3 d-flex flex-column" style="height: 100%; gap: 40px">
          <div style="overflow: auto">
            <app-mini-calendar
              (selectedMonthYear)="goToMonth = $event"
              *ngIf="allDone"
              [dateChanged]="dateChanged"
            ></app-mini-calendar>
          </div>
          <div style="height: 1px; background: #d1d3d3; width: 100%"></div>
          <div class="d-flex flex-column" style="gap: 40px; height: 98vh">
            <div class="img-container" *ngIf="allDone">
              <div
                class="img-auto-scroll"
                *ngFor="let imgUrl of firstListImg; let idx = index"
              >
                <img
                  class="d-block w-100 image"
                  [src]="imgUrl"
                  alt="Slide {{ idx + 1 }}"
                  style="
                    height: 200px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                  "
                />
              </div>
            </div>
            <!-- Loading image -->
            <div class="loading-img" *ngIf="!allDone"></div>
          </div>
        </div>
        <div
          class="col-9 box-layout"
          style="
            background: linear-gradient(
              180deg,
              rgba(43, 60, 61, 0.12) 0%,
              rgba(117, 156, 159, 0.12) 100%
            );
            width: 70%;
          "
        >
          <app-second-variation-calendar
            [currentId]="courseId"
            [getCurrentMonth]="goToMonth"
            (doneLoad)="allDone = $event.done; isCaam = $event.isCaam"
            (returnDateFromCalendar)="dateChanged = $event"
          ></app-second-variation-calendar>
        </div>
      </div>
    </div>
  </div>
</div>
