<section class="h-100 gradient-form" style="background-color: #eee;">

  <div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-xl-12">
      <div class="card rounded-3 text-black">
        <div class="row g-0">
          <div class="col-lg-5">
            <div class="card-body p-md-5 mx-md-4">
              <img src="../../assets/img/logo-afi.png" class="logo" />

              <p class="login-txt">SIGNUP</p>
              <span>Create your account now.</span>
              <div class="form-container">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <!-- ... other form controls ... -->
                  <div class="form-outline mb-4 form-group">
                    <label class="form-label" for="form2Example11">Email</label>
                    <input type="email" id="" class="form-control" placeholder="Enter Email"
                      formControlName="u_email" [ngClass]="{ 'is-invalid': submitted && f['u_email'].errors }" />
                    <div *ngIf="submitted && f['u_email'].errors" class="invalid-feedback">
                      <div *ngIf="f['u_email'].errors['required']">Email is required</div>
                      <div *ngIf="f['u_email'].errors['u_email']">Email is invalid</div>
                    </div>
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example11">Full Name</label>
                    <input type="text" id="" class="form-control" placeholder="Enter Fullname"
                      formControlName="u_full_name"
                      [ngClass]="{ 'is-invalid': submitted && f['u_full_name'].errors }" />
                    <div *ngIf="submitted && f['u_full_name'].errors" class="invalid-feedback">
                      <div *ngIf="f['u_full_name'].errors['required']">Fullname is required</div>
                    </div>
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example22">Password</label>
                    <input type="password" id="" class="form-control" placeholder="Enter Password"
                      formControlName="u_password" [ngClass]="{ 'is-invalid': submitted && f['u_password'].errors }" />
                    <div *ngIf="submitted && f['u_password'].errors" class="invalid-feedback">
                      <div *ngIf="f['u_password'].errors['required']">Password is required</div>
                      <div *ngIf="f['u_password'].errors['minlength']">
                        Password must be at least 6 characters
                      </div>
                    </div>
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example22">Confirm Password</label>
                    <input type="password" id="" class="form-control" placeholder="Re-enter Password"
                      formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }"/>
                    <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                      <div *ngIf="f['confirmPassword'].errors['required']">
                        Confirm Password is required
                      </div>
                      <div *ngIf="f['confirmPassword'].errors['matching']">
                        Confirm Password does not match
                      </div>
                    </div>
                    <!-- <div *ngIf="signupForm.hasError('passwordMismatch')">
                      Passwords do not match!
                    </div> -->
                  </div>

                  <div class="col-12">
                    <button type="submit" class="btn btn-login">Create Account</button>
                  </div>
                  <div class="d-flex justify-content-center mt-2">
                    <span>Already registered ? <span style="font-weight: bold; cursor: pointer;"
                        [routerLink]="'/login'">Login</span></span>
                  </div>
                </form>


              </div>

            </div>
          </div>

          <div class="col-lg-7">
            <div class="bg"></div>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>