import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  goToMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`
  items: number[] = Array(50).fill(0).map((_, i) => i + 1);
  currentFragment: string | null = null;
  fragmentContent: string = 'Default Content or No Fragment';
  fragmentImage: string = '/assets/img/default.jpg';
  fragmentLevel: string = '';
  fragmentDescription: string = '';

  fragmentMapping: { [key: string]: { content: string, image: string, level: string, description: string } } = {
    'rcoc-b': {
      content: 'Remote Pilot Certificate of Competency<br> (RCOC-B)',
      image: '/assets/img/drone_bg-1.svg',
      level: 'BEGINNER',
      description: 'Explore UAV with hands-on training, expert guidance and knowledge for drone pilot career.'
    },
    'evlos': {
      content: 'RCoC (EVLOS) Module 1 : Extended Visual Line of Sight',
      image: '/assets/img/evlos.avif',
      level: 'ADVANCED',
      description: 'Enhance drone skills with our EVLOS course, exploring beyond sight lines.'
    },
    'module_2': {
      content: 'RCoC (AGR) Module 2 : Agricultural UAS Operations',
      image: '/assets/img/module_2.avif',
      level: 'ADVANCED',
      description: 'Specializing in agriculture UAS, we offer training and dispensing for sustainable farming practices.'
    },
    'basic_drone_training': {
      content: 'Basic Drone Training',
      image: '/assets/img/basic_drone_training.avif',
      level: 'BEGINNER',
      description: 'Learn the basics of flying safely and confidently, whether you are a hobbyist or aspiring professional.'
    },
    'agriculture_drone_training': {
      content: 'Agriculture Drone Training',
      image: '/assets/img/agriculture_drone_training.avif',
      level: 'INTERMEDIATE',
      description: 'Master drone tech and data analysis for sustainable agriculture and higher crop yields with us.'
    },
    'aerial_surveillance': {
      content: 'Aerial Surveillance, Mapping & Data Processing',
      image: '/assets/img/aerial_surveillance.avif',
      level: 'INTERMEDIATE',
      description: 'Learn essential skills for monitoring, surveying and capturing aerial imagery.'
    },
    'aerial_photography': {
      content: 'Aerial Photography & Cinematography',
      image: '/assets/img/aerial_photography.avif',
      level: 'INTERMEDIATE',
      description: 'Master aerial shots and storytelling for stunning visual storytelling.'
    },
    'industry': {
      content: 'Aerial Data Acquisition for Industry',
      image: '/assets/img/industry.avif',
      level: 'BEGINNER',
      description: 'Learn drone skills for data collection in industries like agriculture and construction.'
    },
    'thermography': {
      content: 'Fundamentals of Thermography',
      image: '/assets/img/thermography.avif',
      level: 'BEGINNER',
      description: 'Learn infrared technology, thermal cameras, applications, and limitations in thermography.'
    },
  };

  firstListImg: string[] = [
    '../assets/landing-page/first-img/1.1.jpg',
    '../assets/landing-page/first-img/1.2.png',
    '../assets/landing-page/first-img/1.3.jpg',
    '../assets/landing-page/first-img/1.4.jpg',
    '../assets/landing-page/first-img/1.5.jpg',
    '../assets/landing-page/second-img/2.1.png',
    '../assets/landing-page/second-img/2.2.jpg',
    '../assets/landing-page/second-img/2.3.png',
    '../assets/landing-page/second-img/2.4.jpg',
    '../assets/landing-page/second-img/2.5.jpg',
    '../assets/landing-page/third-img/3.1.png',
    '../assets/landing-page/third-img/3.2.jpg',
    '../assets/landing-page/third-img/3.3.jpg',
    '../assets/landing-page/third-img/3.4.jpg',
    '../assets/landing-page/third-img/3.5.jpeg'
  ]


  secondListImg: string[] = [
   
  ]


  thirdListImg: string[] = [
   
  ]

  isCaam: boolean = false;
  allDone: boolean = false;
  dateChanged: any = new Date()

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.currentFragment = fragment;
      this.handleFragmentChange(fragment ?? '');
    });
    
   
  }

  handleFragmentChange(fragment: string) {
    console.log('Fragment changed to:', fragment);
    const fragmentData = this.fragmentMapping[fragment] || {
      content: 'Default Content or No Fragment',
      image: '/assets/img/default.jpg',
      level: '',
      description: ''
    };
    this.fragmentContent = fragmentData.content;
    this.fragmentImage = fragmentData.image;
    this.fragmentLevel = fragmentData.level;
    this.fragmentDescription = fragmentData.description;
  }
  formatDate(date: Date | string) {
    const inputDate = new Date(date);

    const day = inputDate.getDate().toString().padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate

  }
}
