import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss']
})
export class SuccessModalComponent {
display: any;
flex: any;
justify: any;
content: any;

  constructor(private modalRef: NgbModal) {}

@Input() title: string = ''
@Input() message: string = ''
@Input() description: string = ''
@Input() item: string = ''
@Input() item2: string = ''
@Input() item3: string = ''
@Input() item4: string = ''
@Input() isSuccess: boolean = false;
@Input() isSuccessDelete: boolean = false;
@Input() isDelete: boolean = false;
@Input() isWelcome: boolean = false;
@Input() isCongrats: boolean = false;
@Input() isRegister: boolean = false;
@Input() isCheckout: boolean = false;
@Input() isHideCancelBtn: boolean=false;
@Input() customProceedText: string = '';
@Input() isAlign: boolean = true;

@Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();


get btnClass() {

  let currClass = ''
  if (this.isDelete) {
    currClass =  'delete'
  } else if (this.isSuccessDelete || this.isWelcome || this.isRegister || this.isCheckout || this.customProceedText != '') {
    currClass = 'confirm'
  } else if (this.isSuccess || this.isCongrats) {
    currClass = 'confirm center'
  } else if (this.isWelcome) {
    currClass = 'cancel'
  }

  return currClass;
}

get btnText() {
  let textLabel = ''

  if (this.isWelcome || this.isCongrats) {
    textLabel = 'Skip'
  } else {
    textLabel = 'Cancel'
  }

  return textLabel
}
closeModals(action: String) {
  if (action === 'Ok') {
    this.closeModal.emit(true)
    console.log ('>>> Ok button')
  } else {
    this.closeModal.emit(false)
    console.log ('>>> Cancel button')
  }

}
}
