import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CourseGeneralPost, CourseList } from 'src/app/models/course-interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
  constructor(private router: Router, private apiService: ApiService) {}
  @Input() jsonData: CourseList[] = [];

  @Output() isUpdate: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

  onCardClick(id: string) {
    // Navigate to the DetailComponent with the id parameter
    this.router.navigate(['/pages/home', id]);
  }

  viewDetails(id: string) {
    this.router.navigate(['/pages/details', id]);
  }

  updateCourse(type: boolean | undefined, id: string) {
    type = !type
    const all = {
      type,
      id
    }
    this.isUpdate.emit(all)
  }
}
