import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from '../services/permission.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from '../utils/validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
  
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  errorMessage: string = '';
  isShowPassword: boolean = false;

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  submitted: boolean = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
          ],
        ],
      },
    );
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  login() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    } else {
      const email = this.email;
      const password = this.password;
      
      // Call the login API service
      this.apiService.loginUser({ email, password }).subscribe(
        (response) => {
          localStorage.setItem('role', response.user.u_role_id.r_name)
          this.navigateToDashboard();
          this.showSuccessToast(`Welcome, ${response.user.u_full_name}!`);
          console.log('User Permissions after setUserPermissions:', this.permissionService.getUserPermissions());
        },
        (error) => {
          console.error('Login error:', error);
          this.errorMessage = 'Invalid email or password';
          this.showErrorToast('Login failed');
          this.handleLoginError(error);
        }
      );
    }
  }
  
  private handleLoginError(error: Error): void {
    console.error('Login error:', error);
    this.errorMessage = 'Invalid email or password';
    this.showErrorToast('Login failed');
  }
  
  navigateToDashboard() {
    this.router.navigate(['pages/home']);
  }
  
  private showSuccessToast(message: string): void {
    this.toastr.success(message, 'Success', {
      timeOut: 3000,
      progressBar: true,
    });
  }
  
  private showErrorToast(message: string): void {
    this.toastr.error(message, 'Error', {
      timeOut: 3000,
      progressBar: true,
    });
  }
}
