import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, of} from 'rxjs';
import {VenueList} from 'src/app/models/session';
import {User} from 'src/app/models/user';
import {BookingService} from 'src/app/services/booking.service';
import {CourseService} from 'src/app/services/course.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {TDocumentDefinitions} from "pdfmake/interfaces";
import DateUtils from "../../../utils/date";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent {
  userData: User = {
    _id: '',
    u_full_name: '',
    u_role_id: '',
    u_email: '',
    u_phone: '',
    u_password: '',
    u_gender: null,
    u_address_1: null,
    u_address_2: null,
    u_state: null,
    u_city: null,
    u_age: null,
    u_postal_code: null,
    u_company: null,
    u_country: null,
    u_reset_password_otp: null,
    u_one2fly_ID: null,
    u_staff_ID: null,
    u_NRIC_Passport: null,
    u_job_title: null,
    u_total_flight_hours: null,
    u_typeof_UAV_flown: null,
    u_status: false,
    u_profile_image: null,
    u_last_login_timestamp: '',
    u_deletedAt: null,
    u_affiliation: null,
    createdAt: '',
    updatedAt: ''
  };

  constructor(private router: Router, public courseService: CourseService, private bookingService: BookingService, private route: ActivatedRoute) { }

  courseDetails: any = {}
  allVenue: VenueList[] = [];
  sessionDate: string = '20 Nov 2023 - 29 Nov 2023'
  courseId: string = '65a8b595c7a58b3284382bed'

  ngOnInit() {
    this.getOrderDetails()
    const storedUserData = localStorage.getItem('currentUser');
    if (storedUserData) {
      this.userData = JSON.parse(storedUserData);
    }
    console.log('user', this.userData)
    this.courseService.getCourseTrainingCategory('venue').subscribe(res => {
      this.allVenue = res
    })
  }

  getOrderDetails() {
    const data = {
      stripeId: localStorage.getItem('stripeId')!,
      paymentId: localStorage.getItem('paymentId')!
    }
    this.bookingService.checkPayment(data).pipe(
      catchError(error => {
        // Handle the error here
        console.error('Error occurred:', error);

        const id = localStorage.getItem('paymentId') ?? ''
          this.bookingService.getPaymentDetails(id).subscribe(resp => {
            console.log('>>> get payment details', resp)
          })
      return of(null)
      })
    ).subscribe(
      res => {
        // Handle the response here
        const id = localStorage.getItem('paymentId') ?? ''
        this.bookingService.getPaymentDetails(id).subscribe(resp => {
          resp.cart.venue = this.allVenue.filter(item => item?._id == resp?.cart?.s_venue)[0]?.v_display_name
          this.courseDetails = resp
        })
      }
    );
  }

  printCourseDetails() {
    window.print();
  }

  saveDetails() {
    const documentDefinition:TDocumentDefinitions = {
      content: [
        { text: 'Your booking summary: ', style: 'header' },
        { text: this.courseDetails.cart.s_course_id.c_course_id, style: 'title' },
        { text: this.courseDetails.cart.s_course_id.c_course_name, style: 'title' },
        { text: this.courseDetails.cart.s_name, style: 'text' },
        { text: DateUtils.formatDate(this.courseDetails.cart.s_start_date) + ' - ' + DateUtils.formatDate(this.courseDetails.cart.s_end_date), style: 'text' },
        { text: 'Venue: ' + this.courseDetails.cart.venue, style: 'text' },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        title: {
          fontSize: 15,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        text: {
          fontSize: 12,
          margin: [0, 0, 0, 10]
        }
      }
    };
    pdfMake.createPdf(documentDefinition, undefined, undefined, pdfFonts.pdfMake.vfs).download('AFI_Order_Details.pdf');
  }

  goCoursePage() {
    this.router.navigate(['/pages/courses'])
  }

  protected readonly DateUtils = DateUtils;
}
