import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Environment } from 'src/app/environments/environment';
import { ApiService } from 'src/app/services/api.service'; // Adjust the import path

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  eventData: any;
  checkoutForm: FormGroup;
  private baseUrl = Environment.frontendUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService,
  ) {
    this.checkoutForm = this.fb.group({
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      reenterEmail: ['', [Validators.required, Validators.email]],
      phonePrefix: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      agree: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    this.eventData = navigation?.extras?.state?.['eventData'] || JSON.parse(sessionStorage.getItem('eventData') || '{}');

    if (!this.eventData || Object.keys(this.eventData).length === 0) {
      console.error('No event data found in navigation state or session storage');
    } else {
      console.log('Checkout - Event data:', this.eventData);
    }
  }

  onSubmit() {
    if (this.checkoutForm.valid) {
      const formValues = this.checkoutForm.value;
      const paymentData = {
        success_url: `${this.baseUrl}success_payment#{CHECKOUT_SESSION_ID}`,
        cancel_url: `https://lms.aerodyne.institute/`,
        session_id: this.eventData.sessionId,
        u_email: formValues.email,
        u_phone: `${formValues.phonePrefix}${formValues.phoneNumber}`,
        u_full_name: formValues.firstName
      };

      this.apiService.processPayment(paymentData).subscribe(
        response => {
          console.log('Payment response:', response);
          if (response && response.url) {
            window.location.href = response.url; // Redirect to Stripe checkout page
          } else {
            this.toastr.error('Failed to redirect to payment page');
          }
        },
        error => {
          console.error('Payment error:', error);
          this.toastr.error('Failed to process payment');
        }
      );
    } else {
      this.toastr.error('Please complete all required fields', 'Form Incomplete');
    }
  }
}
