<div>
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <img src="../../../assets/img/logo_White.svg" width="100" height="auto" style="cursor: pointer;" (click)="navigatePage('home')">
    <div>
        <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item mtt-5">
                <img src="../../../assets/icon/icon-bell.svg" alt="Bell Icon" width="20" height="20">
            </li> -->
            <!-- <li class="nav-item mtt-5" style="cursor: pointer; position: relative;" (click)="navigatePage('cart')" *ngIf="userData?.u_role_id?.r_display_name == 'trainee'">
                <img src="../../../assets/icon/shopping-cart.svg" alt="Bell Icon" width="20" height="20">
                <div class="total-cart">2</div>
            </li> -->

            <li class="nav-item d-flex align-items-center" style="cursor: pointer;" (click)="toggleDropdown()">
                <img src="../../../assets/icon/icon-avatar.png" alt="Avatar" class="rounded-circle mr-2">
                <div>
                    <p class="mb-0">{{ userData?.u_full_name }}</p>
                    <span>{{ userData?.u_role_id?.r_display_name == 'trainee' ? 'student' : userData?.u_role_id?.r_display_name }}</span>
                </div>

            </li>

        </ul>
    </div>
</nav>
<div *ngIf="showDropdown" class="user-menu">
    <div class="student-info">
            <img src="../../../assets/icon/icon-avatar.png" alt="Avatar" class="rounded-circle mr-2 student-avatar">
        <div class="student-name">
            <span class="mb-0">{{ userData?.u_full_name }}</span>
            <p class="mb-0 email-text">{{ userData?.u_email }}</p>
        </div>
    </div>
  <div class="divider"></div>
  <div class="panel">
    <img src="../../../assets/icon/profile.svg"  alt="profile" >
    <span (click)="goToProfile()">My Profile</span>
  </div>
  <div class="panel" *ngIf="userData?.u_role_id?.r_display_name == 'trainee'">
    <img src="../../../assets/icon/payment.svg"  alt="profile" style="width: 18px;">
    <span (click)="goToPaymentHistory()">Payment History</span>
  </div>
  <div class="divider"></div>
  <div class="panel" (click)="logoutUser()">
    <img src="../../../assets/icon/logout.svg"  alt="logout" >
    <span>Logout</span>
  </div>
</div>

</div>
