<div class="container" >
  <!-- section-top -->
  <div class="d-flex justify-content-between section-top">
    <div>
      <p>TRAINERS</p>
      <span><a [routerLink]="['/pages/home']">Home</a> / Trainers</span>
    </div>
    <div class="button d-flex">
      <!-- <button class="btn btn-export">
          <i class="fa-solid fa-file-export"></i>Export
      </button> -->
      <button class="btn btn-add" (click)="navigateToAddTrainer()">
        <i class="fa fa-add"></i>  New Trainer
      </button>
    </div>
  </div>
  <!-- section-bottom -->
  <div class="section-bottom">
    <!-- filter section -->
    <div class="container" style="padding: 15px !important;">
      <div class="d-flex">
        <div class="sort-dropdown">
          <label for="sort">Sort By:</label>
          <select id="sort">
            <option value="name">All</option>
            <option value="date">Date</option>
            <option value="price">Price</option>
          </select>
        </div>
        <div class="sort-dropdown">
          <label for="sort">Filter By:</label>
          <select id="sort">
            <option value="name">All</option>
            <option value="date">Date</option>
            <option value="price">Price</option>
          </select>
        </div>
      </div>
      <div class="d-flex" style="gap: 10px;">
        <!-- Search input with search icon -->
        <div class="search-container">
          <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="searchTrainer" (keypress)="searchFunc($event)"/>
        </div>
        <div class="view-buttons">
          <button id="list-view" [ngClass]="{'active': isGridView}" (click)="isGridView = false">
            <img src="../../../assets/icon/list-view.svg" />
          </button>
          <button id="grid-view" [ngClass]="{'active': !isGridView}" (click)="isGridView = true">
            <img src="../../../assets/icon/grid-view.svg" />
          </button>
        </div>
      </div>
    </div>
    <app-list-trainer [trainerList]="trainerSearch" *ngIf="!isGridView else gridView"></app-list-trainer>
    <ng-template #gridView>
      <div class="grid-content">
        <div class="trainer-grid" *ngFor="let trainer of trainerSearch" >
          <img class="w-100" alt="profile-pic" [src]="trainer.u_profile_image ?? 'https://mdbcdn.b-cdn.net/img/new/avatars/2.webp'">
          <div class="desc">
            <div class="d-flex justify-content-between w-100">
              <span class="label"> {{trainer.u_staff_ID }}</span>
              <span class="label">RP - 960108055655</span>
            </div>
            <div>
              <span> {{ trainer.u_full_name }} </span>
              <div class="label" style="font-weight: 400;"> {{ trainer.u_email }} </div>
            </div>
            <div class="role-bg"> {{ trainer.job_title }} </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
