import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
userData:any;
showDropdown = false;

  constructor(private router: Router, private toastr: ToastrService, private apiService: ApiService, private elementRef: ElementRef) {}

  ngOnInit() {
    const storedUserData = localStorage.getItem('currentUser');

    if (storedUserData) {
      this.userData = JSON.parse(storedUserData);
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
          this.showDropdown = false;
      }
  }

  navigatePage(type: string) {
    this.router.navigate([`${type == 'cart' ? '/booking/cart' : '/pages/home'}`]);
  }

  logoutUser() {
    this.apiService.logoutUser().subscribe((response) => {
      this.showSuccessToast('Logout Successfully!');
      this.router.navigate(['/login']);

        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenExpires');
        localStorage.removeItem('isRouterLink');
        localStorage.removeItem('role');
    })
  }

  goToProfile() {
    this.router.navigate([`/pages/profile/null`]);
    this.showDropdown = false;
  }

  goToPaymentHistory() {
    this.showDropdown = false;
    this.router.navigate([`/pages/payment/history`]);
  }

  logout(): void {
    this.showSuccessToast('Logout successful');
    this.router.navigate(['/login']);
  }

  private showSuccessToast(message: string): void {
    this.toastr.success(message, 'Success', {
      timeOut: 3000,
      progressBar: true,
    });
  }
}
