import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import Validation from '../utils/validation';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  form: FormGroup = new FormGroup({
    u_full_name: new FormControl(''),
    u_email: new FormControl(''),
    u_password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService , private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    // Initialize the form without default values
    this.form = this.formBuilder.group(
      {
        u_full_name: ['', Validators.required],
        u_email: ['', [Validators.required, Validators.email]],
        u_password: [
          '',
          [
            Validators.required,
            Validators.minLength(6)
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [Validation.match('u_password', 'confirmPassword')],
      }
    );
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  
  
  onSubmit(): void {
   
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    else {
      const formData = this.form.value;
      this.apiService.registerUser(formData).subscribe(
        (response) => {
          console.log('Registration successful', response);
          this.toastr.success(`Registration Success, Thanks ${response.result.u_full_name}`);
          this.router.navigate(['/login']);
        },
        (error) => {
          console.error('Registration failed', error);
          this.toastr.error('Registration Failed', 'Try again');
        }
      );
    }
   
  }
}
