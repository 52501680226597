

<div class="calendar-container">
    <full-calendar #fullCalendar [options]="calendarOptions" [style]="!isDoneLoad ? 'visibility: hidden;': 'visibility:visible;'">
      <ng-template #eventContent let-arg>
        <div style="position: relative; background: #F5F5F5 !important; border-radius: 5px; border: none !important;" [ngClass]="arg.event.extendedProps.isExpired ? 'expired-class' : ''">
          <img src="../../../assets/img/Aerodyne-calendar.JPG" class="bg-img-calendar">
          <div class="fc-event-content cyberjaya" data-session-id="${sessionId}" placement="bottom" [ngbTooltip]="arg.event.extendedProps.isExpired ? null : 'Cyberjaya, Selangor'"  container="body" [ngStyle]="arg.event.extendedProps.isExpired ? {'cursor': 'not-allowed'} : {}">
            <div *ngIf="!arg.event.extendedProps.isExpired && arg.event.extendedProps.emptyseatsPercent <= 50" class="event-banner" style="text-overflow: ellipsis !important; overflow: hidden !important; white-space: nowrap !important;">
              Hurry, only {{ arg.event.extendedProps.seats }} seats left!
            </div>
            <div *ngIf="!arg.event.extendedProps.isExpired && arg.event.extendedProps.emptyseatsPercent > 50 && arg.event.extendedProps.showLastChanceBanner" class="event-banner" style="text-overflow: ellipsis !important; overflow: hidden !important; white-space: nowrap !important;">
              Last Chance, Register Now!
            </div>
            <div class="fc-event-time" style="font-weight: 400; text-overflow: ellipsis !important; ">9:00am - 5:00pm</div>
            <div *ngIf="!arg.event.extendedProps.isExpired" class="fc-event-seats" style="text-overflow: ellipsis !important; overflow: hidden !important; white-space: nowrap !important;">{{ arg.event.extendedProps.seats }} seats available</div>
            <div *ngIf="arg.event.extendedProps.isExpired" class="fc-event-seats" style="text-overflow: ellipsis !important;">No seats left</div>
          </div>
        </div>

      </ng-template>
    </full-calendar>

  </div>


