<div>
    <div class="overlay">
    </div>
    <div class="card-modal">
        <div class="flex card-modal-item">
            <!-- content -->
            <div class="flex card-content">
                <!-- frame -->
                <div class="flex card-frame">
                    <div class="card-frame-text">
                        {{ title }}
                    </div>

                        <div *ngIf="isSuccess">
                            <img src="../../assets/gif/success.gif" class="icon-success" alt="success-img" />
                        </div>
                        <div *ngIf="isSuccessDelete">
                            <img src="../../assets/gif/success-delete.gif" class="icon-success-delete" alt="delete-img"/>
                        </div>
                        <div *ngIf="isDelete">
                            <img src="../../assets/gif/alert.gif" class="icon-alert" alt="alert-img"/>
                        </div>
                        <div *ngIf="isWelcome">
                            <img src="../../assets/gif/welcome.gif" class="icon-welcome" alt="welcome-img"/>
                        </div>
                        <div *ngIf="isCongrats">
                            <img src="../../assets/gif/congratulations.gif" class="icon-congrats" alt="congrats-img"/>
                        </div>
                        <div *ngIf="isRegister || isCheckout">
                            <img src="../../assets/gif/register.gif" class="icon-register" alt="register-img"/>
                        </div>

                    <div class="icon-close" (click)="closeModals('Cancel')">
                        <img src="../../assets/icon/close_24px.svg" alt="close-img" />
                    </div>
                </div>
                <!-- Session -->
                <div class="flex card-session">
                    <div class="flex card-session-header">
                        <div class="flex card-session-frame">
                            <div class="card-session-text-1">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                    <div class="flex card-session-item">
                        <div class="card-session-text-2" [style]="!isAlign ? null : 'align-self: stretch;'">
                            {{ description }}
                        </div>
                        <div class="card-session-text-3" [style]="!isAlign ? null : 'align-self: stretch;'">
                            {{ item }}
                        </div>
                        <div class="card-session-text-3">
                            {{ item2 }}
                        </div>
                        <div class="card-session-text-3">
                            {{ item3 }}
                        </div>
                        <div class="card-session-text-3">
                            {{ item4 }}
                        </div>
                    </div>
                </div>
                 <!-- Session end -->
            </div>
            <!-- footer -->
            <div class="module-footer w-100" style="justify-content: flex-end;">
                    <div *ngIf="!isHideCancelBtn" class="cancel w-50" (click)="closeModals('Cancel')">{{ btnText }}</div>

                    <div [ngClass]="btnClass" class="w-50" (click)="closeModals('Ok')">
                        {{ customProceedText != '' ? customProceedText : isCongrats ? 'Yes, proceed': isRegister ? 'Checkout' : 'Ok' }}

                    </div>

            <!-- footer end -->
            </div>
        </div>
    </div>
</div>

