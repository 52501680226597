import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CourseList } from 'src/app/models/course-interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent {
  constructor(private router: Router, private apiService: ApiService) {
  }

  @ViewChild('listGrid')
  listGrid!: ElementRef;
  @Output() isUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Input() jsonData: CourseList[] = [];
  selectFav: number = 0;
  currentId: string = '';
  currentCourseId: string | null = null;

  onCardClick(id: string) {
    this.currentCourseId = id
    // Navigate to the DetailComponent with the id parameter
    this.router.navigate(['/pages/details', id]);
  }

  updateCourse(type: boolean | undefined, id: string) {
    type = !type

    const all = {
      type,
      id
    }
    
    this.isUpdate.emit(all)
  }



}
