<!-- <div> -->
    <!-- <div class="p-0 d-flex"> -->
        <div class="order-summary d-flex">
            <div class="price-details">
                <div class="price-summary">
                    <div class="price">
                        <div class="price-course-fee">
                            <span class="price-course-fee text">Course Fee:</span>
                            <span class="price-course-fee text">RM {{ totalPrices }}</span>
                        </div>
                    </div>
                    <!-- <div class="discount">
                        <span class="discount text">Discount:</span>
                        <span class="discount text">0.00</span>
                    </div>
                    <div class="discount-details">
                        <input type="text" class="input discount-input" [(ngModel)]="discount_input" name="course_id" placeholder="Discount/Promo Code" />
                        <button class="discount-button">Apply</button>
                    </div> -->
                    <div class="divider" style="width: 100%;"></div>
                    <div class="price-course-fee">
                        <span class="price-course-fee text" style="font-weight: 600;">Total</span>
                        <span class="price-course-fee text" style="font-weight: 600;">RM {{ totalPrices}}</span>
                    </div>
                </div>
                    <button class=" button-checkout button-custom" (click)="paymentUrl == null ? checkoutCart() : navigatePayment(paymentUrl)">{{ paymentUrl != null ? 'Continue Payment' : 'Checkout'}} </button>
            </div>
        </div>
    <!-- </div> -->
<!-- </div> -->
