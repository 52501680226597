<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
    <!-- <div class="header">
      <div class="toggle" (click)="handleSidebarToggle()">
        <span class="chevron"></span>
      </div>
    </div> -->
    <div class="header">
      <div class="toggle" (click)="handleSidebarToggle()">
        <img [src]="isExpanded ? '../../../assets/icon/left-icon.svg' : '../../../assets/icon/double-arrow-right-icon.svg'" alt="Toggle Sidebar" class="double-arrow" />
      </div>
    </div>
    
    <div class="content">
      <div class="link">
        <div class="content" [routerLink]="['/pages/home']" [routerLinkActive]="'active'">
          <div class="icon">
           <img src="../../../assets/icon/home.svg" />
          </div>
          <div class="title">Home</div>
        </div>
      </div>
      <!-- <div class="link"  >
        <div class="content" [routerLink]="['/pages/dashboard']" [routerLinkActive]="'active'" >
          <div class="icon">
           <img src="../../../assets/icon/dashboard-icon.svg" />
          </div>
          <div class="title">Dashboard</div>
        </div>
      </div> -->
      <div class="link">
        <div class="content" [routerLink]="['/pages/courses']" [routerLinkActive]="'active'">
          <div class="icon">
           <img src="../../../assets/icon/clipboard-tick.svg" />
          </div>
          <div class="title">{{ role == 'trainer' ? 'My Sessions' : role == 'trainee' ? 'My Courses' : 'Courses'}}</div>
        </div>
      </div>
      <!-- <div class="link">
        <div class="content" [routerLink]="['/pages/calendar']" [routerLinkActive]="'active'">
          <div class="icon">
           <img src="../../../assets/icon/calendar1.svg" /> 
          </div>
          <div class="title">Calendar</div>
        </div>
      </div>-->
      <div class="link" *appPermission="USER_PERMISSION.VIEW_TRAINER">
        <div class="content" [routerLink]="['/pages/trainers']" [routerLinkActive]="'active'" style="padding-left: 0;">
          <div class="icon" >
           <img src="../../../assets/icon/Trainer.svg" /> 
          </div>
          <div class="title">Trainers</div>
        </div>
      </div> 
     
      <!--  <div class="link">
        <div class="content" [routerLink]="['/pages/quiz']" [routerLinkActive]="'active'">
          <div class="icon">
            <img src="../../../assets/icon/clipboard-tick.svg" /> 
          </div>
          <div class="title">Quiz & Assessment</div>
        </div>
      </div>
      <div class="link">
        <div class="content" [routerLink]="['/pages/exam']" [routerLinkActive]="'active'">
          <div class="icon">
           <img src="../../../assets/icon/tasksquare.svg" /> 
          </div>
          <div class="title">Exams & Certificate</div>
        </div>
      </div> -->
    </div>
  
</div>