<div class="container-fluid" style="width: 100% ; height: 100vh;overflow: auto; padding: 0%;">
  <div class="header">
    <div class="img" [ngStyle]="{'background-image': 'url(' + fragmentImage + ')'}">
      <p class="txt-1">{{ fragmentLevel }}</p>
      <p class="txt-2" [innerHTML]="fragmentContent"></p>
      <p class="txt-1">{{ fragmentDescription }}</p>
    </div>
  </div>

  <div class="container" style="padding: 0px 120px; max-width: none !important;">

    <div class="row" style="margin: 20px 0px;">
      <div class="d-flex justify-content-between w-100">
      <div class="d-flex flex-column" style="margin-bottom: 40px;">
        <span class="head-top">Select your session date</span>
        <span style="font-size: 26px; font-weight: 400; color: #A8A8A8;">Choose the perfect date for your session and
          secure your spot!</span>
      </div>
        <!-- <div class="loading-img"></div> -->
            <div class="d-flex align-items-center justify-content-center" style="gap: 8px;" *ngIf="isCaam">
              <img src="../../../../assets/img/caam.png" alt="caam" style="width: 100px; height: 100px;" >
              <span style="color: black; font-size: 24px; font-weight: 600;">CERTIFIED</span>
            </div>
            </div>
      <div class="d-flex" style="gap: 40px; position: relative;">
        <img src="../../assets/gif/loading.gif" class="loading-image" *ngIf="!allDone"/>
        <!-- <div style="background-color: red;" class="loading-image" ></div> -->

        <div class="col-3 d-flex flex-column" style="height: 100%; gap: 40px;">
          <div style="overflow: auto;">
          
           
            <div class="loading-img" style="height: 74px;" *ngIf="!allDone"></div>
            <app-mini-calendar (selectedMonthYear)="goToMonth = $event" *ngIf="allDone" [dateChanged]="dateChanged"></app-mini-calendar>
           
          </div>

          <div style="height: 1px; background: #D1D3D3; width: 100%;"></div>
          <div class="d-flex flex-column" style="gap: 40px; height: 98vh;">
            <div class="img-container" *ngIf="allDone">
            <div class="img-auto-scroll" *ngFor="let imgUrl of firstListImg; let idx = index">
            <img class="d-block w-100 image" [src]="imgUrl" alt="Slide {{idx + 1}}"
              style="height: 200px; width: 100%; object-fit: cover; border-radius: 6px;" >
          </div>
          </div>
            <!-- Loading image -->
             <div class="loading-img" *ngIf="!allDone"></div>

          </div>
        </div>
        <div class="col-9 box-layout" style="background: linear-gradient(180deg, rgba(43, 60, 61, 0.12) 0%, rgba(117, 156, 159, 0.12) 100%); width: 70%;">
          <app-calendar [currentFragment]="currentFragment" [getCurrentMonth]="goToMonth" (doneLoad)="allDone = $event.done; isCaam = $event.isCaam" (returnDateFromCalendar)="dateChanged = $event"></app-calendar>

        </div>
      </div>

    </div>
  </div>

</div>