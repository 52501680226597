<div>
    <form (ngSubmit)="addNewTrainer()" [formGroup]="trainerForm">
    <div class="container">
        <div class="module-header">
            <span style="font-size: 20px; font-weight: 400;"> Trainer Details </span>
            <img src="../../../assets/icon/close.svg" alt="close-module" (click)="closeModals()" style="cursor: pointer;">
        </div>

      
        <div class="module-content mt-3">
            <div class="form-module">
                <div class="d-flex w-100" style="gap: 8px">
                    <div class="w-50">
                        <label class="w-100">Full Name <span>*</span></label>
                        <input type="text" class="input" [(ngModel)]="trainerInfo.u_full_name" formControlName="u_full_name" [ngClass]="{ 'is-invalid': submitted && f['u_full_name'].errors }"/>
                        <div *ngIf="submitted && f['u_full_name'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_full_name'].errors['required']">Fullname is required</div>
                          </div>
                    </div>
                    <div class="w-50">
                        <label class="w-100">Email <span>*</span></label>
                        <input type="text" class="input" [(ngModel)]="trainerInfo.u_email" formControlName="u_email" [ngClass]="{ 'is-invalid': submitted && f['u_email'].errors }"/>
                        <div *ngIf="submitted && f['u_email'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_email'].errors['required']">Email is required</div>
                          </div>
                    </div>
                </div>
                <div class="d-flex w-100" style="gap: 8px">
                    <div class="w-50">
                        <label class="w-100">one2fly ID <span>*</span></label>
                        <input type="text" class="input" [(ngModel)]="trainerInfo.u_one2fly_ID" formControlName="u_one2fly_ID" [ngClass]="{ 'is-invalid': submitted && f['u_one2fly_ID'].errors }"/>
                        <div *ngIf="submitted && f['u_one2fly_ID'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_one2fly_ID'].errors['required']">One2fly ID is required</div>
                          </div>
                    </div>
                    <div class="w-50">
                        <label class="w-100">Staff ID <span>*</span></label>
                        <input type="text" class="input" [(ngModel)]="trainerInfo.u_staff_ID" formControlName="u_staff_ID" [ngClass]="{ 'is-invalid': submitted && f['u_staff_ID'].errors }"/>
                        <div *ngIf="submitted && f['u_staff_ID'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_staff_ID'].errors['required']">Staff ID is required</div>
                          </div>
                    </div>
                </div>
                <div class="d-flex w-100" style="gap: 8px">
                    <div class="w-50">
                        <label class="w-100">NRIC/Passport <span>*</span></label>
                        <input type="number" class="input" [(ngModel)]="trainerInfo.u_NRIC_Passport" formControlName="u_NRIC_Passport" [ngClass]="{ 'is-invalid': submitted && f['u_NRIC_Passport'].errors }"/>
                        <div *ngIf="submitted && f['u_NRIC_Passport'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_NRIC_Passport'].errors['required']">NRIC/Passport is required</div>
                          </div>
                    </div>
                    <div class="w-50">
                        <label class="w-100">Phone Number <span>*</span></label>
                        <input type="number" class="input" [(ngModel)]="trainerInfo.u_phone_no" formControlName="u_phone_no" [ngClass]="{ 'is-invalid': submitted && f['u_phone_no'].errors }"/>
                        <div *ngIf="submitted && f['u_phone_no'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_phone_no'].errors['required']">Phone Number is required</div>
                          </div>
                    </div>
                </div>
                <div class="d-flex w-100" style="gap: 8px">
                    <div class="w-50">
                        <label class="w-100">Job Title <span>*</span></label>
                        <input type="text" class="input"
                               placeholder="Enter Job Title"
                               [(ngModel)]="trainerInfo.u_job_title"
                               formControlName="u_job_title"
                               [ngClass]="{ 'is-invalid': submitted && f['u_job_title'].errors }">
                        <div *ngIf="submitted && f['u_job_title'].errors" class="invalid-feedback">
                          <div *ngIf="f['u_job_title'].errors['required']">Job Title is required</div>
                        </div>
                      </div>
                    <div class="w-50">
                        <label class="w-100">Total Flight Hours <span>*</span></label>
                        <input type="number" class="input" [(ngModel)]="trainerInfo.u_total_flight_hours" formControlName="u_total_flight_hours" [ngClass]="{ 'is-invalid': submitted && f['u_total_flight_hours'].errors }"/>
                        <div *ngIf="submitted && f['u_total_flight_hours'].errors" class="invalid-feedback">
                            <div *ngIf="f['u_total_flight_hours'].errors['required']">Flight Hours is required</div>
                          </div>
                    </div>
                </div>
                <div class="w-100">
                <label class="w-100">Type of UAV flown <span>*</span></label>
                <ng-select [multiple]="true" [(ngModel)]="trainerInfo.u_typeof_UAV_flown" formControlName="u_typeof_UAV_flown" [ngClass]="{ 'is-invalid': submitted && f['u_typeof_UAV_flown'].errors }">
                    <ng-option *ngFor="let item of droneFlownList" [value]="item._id">{{ item.dt_display_name }}</ng-option>
                </ng-select>
                <div *ngIf="submitted && f['u_typeof_UAV_flown'].errors" class="invalid-feedback">
                    <div *ngIf="f['u_typeof_UAV_flown'].errors['required']">UAV type is required</div>
                  </div>
                </div>
    
    
            </div>
        </div>

  
    </div>
    <div class="module-footer w-100">
        <div class="cancel w-50" (click)="closeModals()">Cancel</div>
        <button type="submit" class="confirm w-50">Save</button>
    </div>
</form>
</div>