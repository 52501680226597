    <div class="background-container">
        <div class="content">
            <!-- Header -->
            <div class="header">
                <img src="/assets/img/logo_afi.svg" alt="AFI Logo" class="logo">
                <div class="payment-id">
                    <p>Payment ID:</p>
                    <span>{{ eventData.paymentId }}</span>
                </div>
            </div>
            <div class="body">
                <p>Booking Confirmed</p>
                <span>Hello, {{ eventData.userName ? eventData.userName : 'User' }}! Congratulations on booking your session
                    at AFI. <br>
                    Get ready to explore new horizons and enhance your skills with our drone course. We look forward to
                    seeing you!</span>


                <div class="info">
                    <div class="info-icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <span>Please make sure you have all necessary documents, such as your registration confirmation, ID, and
                        any prerequisites for your course.</span>
                </div>

                <div class="booking-summary">
                    Booking Summary
                </div>
                <table class="course-table">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>Course Name</th>
                            <th>Session Name & Date</th>
                            <th>Location</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>{{ eventData.courseName }}</td>
                            <td>{{ eventData.sessionName }} - {{ eventData.startDate }}</td>
                            <td>{{ eventData.location }}</td>
                            <td>{{ eventData.price }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="booking-summary">
                    Payment Details
                </div>
                <div class="row-bottom">
                    <div class="left-text">Total Amount Paid</div>
                    <div class="right-text">{{ eventData.price }}</div>
                </div>

                <div class="button-container">
                    <button class="action-button" (click)="printPage()">Preview</button>
                </div>

                <div class="footer">
                    <p class="top">Your booking details automatically generated!</p>
                    <p class="bot">If you have any inquiries, please contact the admin directly at <span class="num">+03
                            8310 9200 </span> or email us at afi@aerodyne.group.<br>
                        For more information about other courses, visit our website at <a
                            href="https://aerodyne.institute/">https://aerodyne.institute/</a>.</p>
                </div>
                <div class="row">
                    <img class="" src="/assets/img/next-gen.png" />
                </div>

                <div class="footer-text">Discover growth through transformative learning.<br> Elevate potential with hands-on training for personal and professional advancement.</div>
            </div>
        </div>
    </div>