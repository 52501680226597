import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from './services/permission.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerService: PermissionService,
    ) { }

    @Input() 
    set appPermission(permission: any) {
      this.isGranted(permission);
    } 
    // @Input() appPermission: any;

    ngOnInit(): void {
    }

  
    private isGranted(permission:any) {
    
      if (this.permissionManagerService.isGranted(permission)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
}
