<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
    <div class="fixed-layout-page">

        <div class="content">
            <div class="left-box">
                <!-- logo left-->
                <img src="/assets/img/logo_afi.svg" alt="AFI Logo" class="logo">
                <p class="head">Be the Next-Gen World Class Drone Pilot</p>
                <p class="sub">Become a skilled drone pilot with our comprehensive training in drone operation, safety,
                    and industry standards using state-of-the-art equipment.</p>

                <!-- First Name -->
                <div class="form-group">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" formControlName="firstName" class="form-control w-100"
                        placeholder="First Name">
                    <div *ngIf="checkoutForm.get('firstName')?.invalid && checkoutForm.get('firstName')?.touched"
                        class="text-danger">First Name is required</div>
                </div>
                <!-- Email -->
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" formControlName="email" class="form-control w-100"
                        placeholder="Email">
                    <div *ngIf="checkoutForm.get('email')?.invalid && checkoutForm.get('email')?.touched"
                        class="text-danger">Valid Email is required</div>
                </div>
                <!-- Reenter Email -->
                <div class="form-group">
                    <label for="reenterEmail">Reenter Email</label>
                    <input type="email" id="reenterEmail" formControlName="reenterEmail" class="form-control w-100"
                        placeholder="Reenter Email">
                    <div *ngIf="checkoutForm.get('reenterEmail')?.invalid && checkoutForm.get('reenterEmail')?.touched"
                        class="text-danger">Valid Email is required</div>
                </div>
                <!-- Phone No -->
                <div class="form-group phone-group">
                    <label for="phone">Phone No</label>
                    <div class="phone-inputs">
                        <select formControlName="phonePrefix" class="form-control" style="width: 20%;">
                            <option value="010">010</option>
                            <option value="011">011</option>
                            <option value="012">012</option>
                            <option value="013">013</option>
                            <option value="014">014</option>
                            <option value="015">015</option>
                            <option value="016">016</option>
                            <option value="017">017</option>
                            <option value="018">018</option>
                            <option value="019">019</option>
                        </select>
                        <input type="text" id="phone" formControlName="phoneNumber" class="form-control"
                            placeholder="Phone Number" style="width: 78%">
                    </div>
                    <div *ngIf="checkoutForm.get('phoneNumber')?.invalid && checkoutForm.get('phoneNumber')?.touched"
                        class="text-danger">Phone Number is required</div>
                </div>
                <!-- Checkbox Agree -->
                <div class="form-group">
                    <div class="checkbox-container">
                        <input type="checkbox" id="agree" formControlName="agree">
                        <label for="agree">I agree to the
                            <span class="privacy">
                            <a href="https://aerodyne.institute/privacy-notice" target="_blank" class="privacy">Privacy Policy</a> & 
                            <a href="https://aerodyne.institute/terms&conditions" target="_blank" class="privacy">Terms & Conditions</a>
                        </span>
                        </label>
                        
                    </div>
                    <div *ngIf="checkoutForm.get('agree')?.invalid && checkoutForm.get('agree')?.touched"
                        class="text-danger">
                        You must agree to the terms and conditions
                    </div>
                </div>

            </div>
            <div class="right-boxes">
                <div class="right-box">
                    <p class="head">Booking Summary</p>
                    <p class="description">{{ eventData.title }}</p>
                    <p class="venue">{{ eventData.location }}</p>
                    <p class="date">{{ eventData.start }} - {{ eventData.end }}</p>
                </div>
                <div class="right-box">
                    <p class="head">Price Summary</p>
                    <div class="course-fee-container">
                        <div class="course-fee-item">
                            <div>Course Fee:</div>
                            <div>{{ eventData.price | number:'1.0-0' }}</div>
                        </div>
                    </div>
                    <div class="total-fee-container">
                        <div class="total-fee-item">
                            <div>Total:</div>
                            <div class="total">RM {{ eventData.price | number:'1.0-0' }}</div>

                        </div>
                    </div>
                </div>
                <button type="submit" class="submit-button">Pay Now</button>


            </div>
        </div>

    </div>
</form>