import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ApiService } from 'src/app/services/api.service'; // Adjust the import path as necessary
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from 'src/app/services/booking.service';
import { SuccessModalComponent } from 'src/app/components/global-modal/global-modal.component';
@Component({
  selector: 'app-second-variation-calendar',
  templateUrl: './second-variation-calendar.component.html',
  styleUrls: ['./second-variation-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SecondVariationCalendarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fullCalendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('fullCalendarContainer', { static: true })
  fullCalendarContainer!: ElementRef;
  @Input() currentId: string | null = null;
  @Output() currentSessionList: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnDateFromCalendar: EventEmitter<any> = new EventEmitter<any>();
  @Output() doneLoad: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  getCurrentMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`;
  calendarOptions: any;
  weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  currentMonthDisplay: Date | null = null;
  currentEndMonthDisplay: Date | null = null;
  isDoneLoad: boolean = false;
  allSessions: any = [];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private courseService: CourseService,
    private modalRef: NgbModal,
    private bookingApiService: BookingService,
  ) {}

  ngOnInit() {
    this.initializeCalendarOptions();

    if (this.currentId) {
      this.fetchAndSetEvents(this.currentId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentId'] && changes['currentId'].currentValue) {
      this.handleFragmentChange(this.currentId!);
    }

    if (changes['getCurrentMonth'] && this.calendarComponent) {
      const monthYearString = this.getCurrentMonth;

      // Parse the month and year
      const [monthName, year] = monthYearString.split(' ');

      // Convert the month name to a month index (0-based index: January is 0, February is 1, etc.)
      const monthIndex = new Date(`${monthName} 1`).getMonth();

      // Create a Date object with the parsed year and month, setting the day to the first of the month
      const date = new Date(parseInt(year, 10), monthIndex, 1);
      this.calendarComponent.getApi().gotoDate(date);
    }
  }

  ngAfterViewInit() {
    // This ensures that the calendarComponent is initialized
    this.addCustomDayHeader();
  }


  handleFragmentChange(id: string) {
    if (id) {
      this.fetchAndSetEvents(id);
    }
  }

  fetchAndSetEvents(id: string) {
    this.courseService.getCourseDetails(id).subscribe(
      (data) => {
        this.allSessions = data;
        this.updateCalendarEvents(data);
        console.log(data, "call data calendar");
      },
      (error) => {
        console.error('Error fetching data from API:', error);
      }
    );
  }

  initializeCalendarOptions() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    this.calendarOptions = {
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',
      initialDate: formattedDate,
      headerToolbar: {
        right: 'prev,today,next',
        left: 'title',
        // right: 'dayGridMonth,dayGridWeek,dayGridDay'
      },
      buttonText: {
        today: 'Today',
      },

      firstDay: 1, // Start with Monday
      events: [],
      fixedWeekCount: false,
      showNonCurrentDates: false,
      contentHeight: 600,
      eventContent: this.renderEventContent.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventDidMount: this.handleEventMount,
      viewDidMount: this.onViewDidMount.bind(this),
      datesSet: this.onDatesSet.bind(this), // Add datesSet to handle view changes
    };
  }

  onViewDidMount(info: any) {
    this.checkAndToggleDayHeaders();
  }

  onDatesSet(info: any) {
    this.checkAndToggleDayHeaders();
    this.logVisibleDateRange();
  }

  logVisibleDateRange() {
    this.calendarComponent.getApi().removeAllEvents();

    const view = this.calendarComponent.getApi().view;
    this.currentMonthDisplay = view.currentStart;
    this.currentEndMonthDisplay = view.currentEnd;

    this.returnDateFromCalendar.emit(view.currentStart);
    this.updateCalendarEvents(this.allSessions!);
  }

  addCustomDayHeader() {
    setTimeout(() => {
      const calendarHeader = document.querySelector('.fc-header-toolbar');
      if (calendarHeader) {
        const existingCustomHeader =
          document.querySelector('.custom-day-header');
        if (existingCustomHeader) {
          existingCustomHeader.remove();
        }

        const customDayHeader = document.createElement('div');
        customDayHeader.className = 'custom-day-header';

        this.weekDays.forEach((day) => {
          const dayElement = document.createElement('div');
          dayElement.className = 'custom-day';
          dayElement.textContent = day;
          customDayHeader.appendChild(dayElement);
        });

        calendarHeader.insertAdjacentElement('afterend', customDayHeader);
      }
      this.checkAndToggleDayHeaders(); // Ensure header visibility is correct after adding
    }, 0);
  }

  checkAndToggleDayHeaders() {
    if (this.calendarComponent) {
      setTimeout(() => {
        const view = this.calendarComponent.getApi().view;
        const customDayHeader = document.querySelector('.custom-day-header');
        if (customDayHeader) {
          if (view.type === 'dayGridDay') {
            customDayHeader.classList.add('hide');
          } else {
            customDayHeader.classList.remove('hide');
          }
        }
      }, 0);
    }
  }

  updateCalendarEvents(events: any) {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      console.log('>> Sessions:', events.sessions);

      let currentData: any = [];
      if (events.sessions) {
        events.sessions.forEach((item: any) => {
          const isValidDate =
            new Date(item.s_start_date!) >= new Date(this.currentMonthDisplay!) &&
            new Date(item.s_start_date!) <= new Date(this.currentEndMonthDisplay!) &&
            new Date(item.s_end_date!) >= new Date(this.currentMonthDisplay!) &&
            new Date(item.s_end_date!) <= new Date(this.currentEndMonthDisplay!);

          if (!isValidDate) {
            calendarApi.getEventById(item.id)?.remove();
          } else {
            currentData.push(item);
          }
        });
      } else {
        console.warn('Sessions is undefined or not an array');
      }

      if (events.sessions && events.sessions.length != 0) {
        this.doneLoad.emit({
          done: true,
          isCaam: events.c_caam_certified,
        });
        this.addEvents(events);
      }
    }
  }


  addEvents(events: any) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.removeAllEvents();

    const courseName = events.c_course_name;
    const numberOfPax = events.c_no_of_pax;
    const pricePerPax = events.c_price_per_pax;

    // console.log("Course Name:", courseName);
    // console.log("Number of Pax:", numberOfPax);
    // console.log("Price per Pax:", pricePerPax);

    // Ensure that events.sessions exists and is an array
    if (events.sessions && Array.isArray(events.sessions)) {
      events.sessions.forEach((event: any) => {
        const title = courseName;
        const start = this.formatDate(event.s_start_date);

        // console.log("Event Start Date (Formatted):", start);

        // Adding one more day to the end date
        const end = this.addOneDay(event.s_end_date);
        const emptyseatsPercent =
          ((numberOfPax - event.students.length) * 100) / numberOfPax;
        const seats = numberOfPax - event.students.length;
        const startTime = event.c_start_time || 'N/A';
        const endTime = event.c_end_time || 'N/A';

        // console.log("Seats Left:", seats);
        // console.log("Start Time:", startTime, "End Time:", endTime);

        const time = startTime !== 'N/A' && endTime !== 'N/A'
          ? `${startTime} - ${endTime}`
          : 'Time not specified';
        const location = event.s_venue;

        // console.log("Location:", location);

        const sessionId = event._id;
        const price = pricePerPax;
        const id = sessionId.toString();
        const startDate = new Date(event.s_start_date);
        const today = new Date();
        const timeDiff = Math.abs(startDate.getTime() - today.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const showLastChanceBanner = diffDays <= 21;

        // console.log("Days until Event:", diffDays, "Show Last Chance Banner:", showLastChanceBanner);

        calendarApi.addEvent({
          id,
          title,
          start,
          end,
          extendedProps: {
            seats,
            time,
            location,
            sessionId,
            title,
            start,
            end: this.formatDate(event.s_end_date),
            startTime,
            endTime,
            price,
            isCaam: event.s_course_id ? event.s_course_id.c_caam_certified : false,
            emptyseatsPercent,
            showLastChanceBanner,
            isExpired:
              new Date() > new Date(event.s_end_date) ||
              (new Date() >= new Date(event.s_start_date) &&
                new Date() <= new Date(event.s_end_date)) ||
              this.isWithin3WorkingDays(event.s_start_date) ||
              !event.s_status,
          },
          description: `${seats} seats available`,
        });

        // console.log("Event Added to Calendar:", title, start, end, location);
      });
    } else {
      console.warn('No sessions available or sessions is not an array.');
    }

    calendarApi.render();
    this.isDoneLoad = true;
  }


  isWithin3WorkingDays(date: string): boolean {
    const dateObj = new Date(date);
    const today = new Date();
    let workingDays = 0;

    // Calculate the number of working days between today and dateObj
    while (today <= dateObj) {
      if (this.isWeekday(today)) {
        workingDays++;
      }
      today.setDate(today.getDate() + 1);
    }

    return workingDays <= 3;
  }

  isWeekday(date: Date): boolean {
    return date.getDay() >= 1 && date.getDay() <= 5;
  }

  addOneDay(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  changeView(view: string) {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.changeView(view);
    }
  }

  renderEventContent(eventInfo: any) {
    const { event } = eventInfo;
    const seat = event.extendedProps.seats;
    // const time = event.extendedProps.time;
    const sessionId = event.extendedProps.sessionId;
    const locationClass =
      event.extendedProps.location === 'Cyberjaya'
        ? 'cyberjaya'
        : event.extendedProps.location === 'Sungai Petani'
        ? 'sungai_petani'
        : '';

    return {
      html: `
      <div class="fc-event-content ${locationClass}" data-session-id="${sessionId}" placement="top" ngbTooltip="Tooltip on top">
        <div class="fc-event-title">${event.title}</div>
       <div class="fc-event-time">9:00am - 5:00pm</div>
        <div class="fc-event-seats color-red">${seat} seats available</div>
      </div>
    `,
    };

    // // const event = arg.event;
    // const el = this.renderer.createElement('div');
    // const title = this.renderer.createElement('div');
    // const time = this.renderer.createElement('div');
    // const seats = this.renderer.createElement('div');

    // title.classList.add('fc-event-title')
    // title.innerHTML = event.title
    // time.classList.add('fc-event-time')
    // time.innerHTML = '9:00am - 5:00pm'
    // seats.classList.add('fc-event-seats')
    // seats.classList.add('color-red')
    // seats.innerHTML = `${seat} seats available`

    // el.setAttribute('ngbTooltip', 'AAA');
    // el.setAttribute('placement', 'top');
    // el.setAttribute('data-session-id', sessionId)
    // el.classList.add('fc-event-content')
    // el.classList.add(`${locationClass}`)
    // el.appendChild(title)
    // el.appendChild(time)
    // el.appendChild(seats)
    // return { domNodes: [el] };
  }

  handleEventClick(info: any) {
    if (!info.event.extendedProps.isExpired) {
      const eventData = {
        title: info.event.extendedProps.title,
        start: info.event.extendedProps.start,
        end: info.event.extendedProps.end,
        seats: info.event.extendedProps.seats,
        startTime: info.event.extendedProps.startTime,
        endTime: info.event.extendedProps.endTime,
        location: info.event.extendedProps.location,
        sessionId: info.event.extendedProps.sessionId,
        price: info.event.extendedProps.price,
      };

      // Open confirmation modal
      let modalRef = this.modalRef.open(SuccessModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.isRegister = true;
      modalRef.componentInstance.isAlign = false;
      modalRef.componentInstance.title = 'Confirm Register';
      modalRef.componentInstance.message = `Are you sure you want to register for this course?`;
      modalRef.componentInstance.item = `Course Name: ${info.event.extendedProps.title}`;
      const sessionName = info.event.extendedProps.title;
      modalRef.componentInstance.item2 = `Session Name: ${sessionName}`;
      const sessionDates = `${this.formatDate(info.event.extendedProps.start)} - ${this.formatDate(info.event.extendedProps.end)}`;
      modalRef.componentInstance.item3 = `Session Date: ${sessionDates}`;
      modalRef.componentInstance.item4 = `Price: RM${info.event.extendedProps.price}`;

      // Handle modal actions
      modalRef.componentInstance.closeModal.subscribe((action: boolean) => {
        if (action) {
          // If confirmed
          modalRef.close();
          this.bookingApiService.checkoutPayment(info.event.extendedProps.sessionId).subscribe(res => {
            console.log('>> Proceeding to payment', res);

            // Store payment details in localStorage
            localStorage.setItem('paymentId', res.payment_id);
            localStorage.setItem('stripeId', res.stripe_id);

            // Redirect to the payment URL
            window.location.replace(res.url);
          });
        } else {
          // If not confirmed
          modalRef.close();
        }
      });
    }
  }


  handleEventMount(eventInfo: any) {
    // No need to add class here as it's already handled in renderEventContent
  }
}
