import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseModule, ClassType, FileObj, FileCustom } from 'src/app/models/course-interface';
import { PreviewPdfComponent } from 'src/app/components/create-course/create-modules/preview-pdf/preview-pdf.component';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-module',
  templateUrl: './edit-module.component.html',
  styleUrls: ['./edit-module.component.scss']
})
export class EditModuleComponent {

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private courseService: CourseService, private modalRef: NgbModal, private apiCommonService: CommonService, private apiService: ApiService) { }

  moduleForm: FormGroup = new FormGroup({
    cm_title: new FormControl(''),
    cm_description: new FormControl(''),
    cm_class_type: new FormControl(''),
    cm_duration: new FormControl(''),
    cm_url: new FormControl(''),
    cm_allow_access: new FormControl(''),
  })

  submitted: boolean = false;

  fileInputs: FileObj[] = [];

  @Input() courseId: string = '';
  @Input() courseModuleId: string = '';
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Input() classType: ClassType[] = [];

  modulePost: CourseModule = {
    _id: '',
    cm_title: '',
    cm_description: '',
    cm_course_id: '',
    cm_class_type: 'class_type',
    cm_duration: 0,
    cm_url: '',
    cm_access: false,
    cm_files: [],
    cm_status: false,
    cm_deletedAt: null,
    createdAt: '',
    updatedAt: '',
    cm_type: '',
    __v: 0,
    cm_allow_access: false,
    files: []
  }

  get f(): { [key: string]: AbstractControl } {
    return this.moduleForm.controls;
  }

  ngOnInit() {

    this.moduleForm = this.formBuilder.group(
      {
        cm_title: ['', [Validators.required]],
        cm_description: ['', [Validators.required]],
        cm_class_type: ['', [Validators.required]],
        cm_duration: ['', [Validators.required]],
        cm_url: [],
        cm_allow_access: [],
      },
    );

    this.getModuleDetails()

  }

  getModuleDetails() {
    this.courseService.getCourseModuleById(this.courseModuleId).subscribe(res => {
      const courseModuleDetails = res

      this.modulePost = courseModuleDetails
      if (Array.isArray(res) && res.length > 0) {
        this.modulePost = res[0] as CourseModule;

      } else if (!Array.isArray(res)) {
        this.modulePost = res as CourseModule;
      }

      this.modulePost.files = []
    });
  }

  closeModals() {
    this.modalRef.dismissAll()
  }

  handleFileHere(event: Event) {
    const input = event.target as HTMLInputElement;
    const files: FileList | null = input.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i], this.modulePost.files!.length);
      }
    }
  }

  uploadFile(file: File, i: number) {
    const data: FileCustom = {
      progress: 0,
      fileName: file.name,
      urls: '',
      files: file
    }

    this.modulePost.files!.push(data)

    // Simulate upload progress
    const interval = setInterval(() => {
      this.modulePost.files![i].progress += 10
      if (this.modulePost.files![i].progress === 100) {
        clearInterval(interval);
        const fileUrl = URL.createObjectURL(file);
        this.modulePost.files![i].urls = fileUrl;
      }
    }, 500);
  }


  viewPdf(i: number): void {
    const pdfUrl = this.modulePost.files![i].urls;
    const pdfName = this.modulePost.files![i].fileName;

    if (pdfUrl) {
      const viewPdf = this.modalRef.open(PreviewPdfComponent, {
        centered: true,
        backdrop: 'static',
        windowClass: 'pdf-modal-width'
      });

      viewPdf.componentInstance.pdfUrl = pdfUrl;
      viewPdf.componentInstance.pdfName = pdfName;

      viewPdf.componentInstance.closeModal.subscribe(() => {
        viewPdf.close();
      });
    }
  }

  viewExistingPDF(item: FileObj) {

    this.apiService.getPdfFile(item.f_path).subscribe(res => {

      const viewPdf = this.modalRef.open(PreviewPdfComponent, {
        centered: true,
        backdrop: 'static',
        windowClass: 'pdf-modal-width'
      })

      viewPdf.componentInstance.pdfUrl = res.url
      viewPdf.componentInstance.pdfName = item.f_path.split('/')[1]
      viewPdf.componentInstance.closeModal.subscribe(() => {
        viewPdf.close();
      });


    })
  }

  saveModule() {
    this.submitted = true;

    if (this.moduleForm.invalid) {
      return;
    }
    else {
      const formData = new FormData();

      formData.append('cm_course_id', this.modulePost.cm_course_id);
      formData.append('cm_title', this.modulePost.cm_title);
      formData.append('cm_description', this.modulePost.cm_description);
      formData.append('cm_class_type', this.modulePost.cm_class_type);
      formData.append('cm_duration', String(this.modulePost.cm_duration));
      formData.append('cm_url', this.modulePost.cm_url);
      formData.append('cm_allow_access', String(this.modulePost.cm_allow_access));

      this.courseService.updateCourseModule(formData, this.courseModuleId).subscribe((response) => {

        if (this.modulePost.files?.length != 0) {
          const formFile = new FormData();
          formFile.append('prefix', 'course_module');
          this.modulePost.files!.forEach((file, index) => {
            formFile.append('files', file.files);
          });
          formFile.append('prefix_id', this.courseModuleId);

          this.apiCommonService.addFiles(formFile).subscribe(res => {
           console.log('>> success upload file')
          })
        }

        this.toastr.success('Successfully updated modules', 'Success', {
          timeOut: 3000,
          progressBar: true,
        });
        this.modalRef.dismissAll();
        this.closeModal.emit();

      })


    }
  }

}
