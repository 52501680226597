import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TrainerPost } from 'src/app/models/trainers';
import { DroneFlownList, JobTitle, Role, User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-add-new-trainer',
  templateUrl: './add-new-trainer.component.html',
  styleUrls: ['./add-new-trainer.component.scss']
})
export class AddNewTrainerComponent {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor(private modalRef: NgbModal, private apiService: ApiService, private toastr: ToastrService, private formBuilder: FormBuilder, private courseService: CourseService) {}

  droneFlownList: DroneFlownList[] = [];
  jobTitle: JobTitle[] = [];
  trainerInfo: TrainerPost = {
    u_full_name: '',
    u_role_id: '',
    u_email: '',
    u_affiliation: '',
    u_phone_no: '',
    u_one2fly_ID: null,
    u_staff_ID: null,
    u_NRIC_Passport: '',
    u_job_title: null,
    u_total_flight_hours: 0,
    u_typeof_UAV_flown: []
  };
  name: string = '';
  email: string = '';
  one2flyID: string = '';

  trainerForm: FormGroup = new FormGroup({
    u_full_name: new FormControl(''),
    u_email: new FormControl(''),
    u_one2fly_ID: new FormControl(''),
    u_staff_ID: new FormControl(''),
    u_NRIC_Passport: new FormControl(''),
    u_phone_no: new FormControl(''),
    u_job_title: new FormControl(''),
    u_total_flight_hours: new FormControl(''),
    u_typeof_UAV_flown: new FormControl(''),
  })

  submitted: boolean = false;


  get f(): { [key: string]: AbstractControl } {
    return this.trainerForm.controls;
  }


  ngOnInit() {
    this.courseService.getCourseTrainingCategory('job_title').subscribe(res => {
      this.jobTitle = res;
    });

    this.courseService.getCourseTrainingCategory('drone_type').subscribe(res => {
      this.droneFlownList = res;
    });


    this.apiService.getAllRoles().subscribe(res => {
      this.trainerInfo.u_role_id = res.filter((item: Role) => item.r_display_name == 'trainer')[0]._id
    })

    this.trainerForm = this.formBuilder.group(
      {
        u_full_name: ['', [Validators.required]],
        u_email: ['', [Validators.required]],
        u_one2fly_ID: ['', [Validators.required]],
        u_staff_ID: ['', [Validators.required]],
        u_NRIC_Passport: ['', [Validators.required]],
        u_phone_no: ['', [Validators.required]],
        u_job_title: ['', [Validators.required]],
        u_total_flight_hours: ['', [Validators.required]],
        u_typeof_UAV_flown: ['', [Validators.required]],
      },
    );
  }

  closeModals() {
    this.modalRef.dismissAll()
  }

  addNewTrainer() {
    this.submitted = true;
    if (this.trainerForm.invalid) {
      return;
    }
    else {
      const postTrainer = {
        ...this.trainerInfo,
        u_NRIC_Passport: this.trainerInfo.u_NRIC_Passport?.toString(),
        u_total_flight_hours:  this.trainerInfo.u_typeof_UAV_flown?.toString(),
      }

      this.apiService.addNewTrainer(postTrainer).subscribe(
        {
          next: res => {
            if (res.result) {
              this.modalRef.dismissAll();
              this.closeModal.emit();
            }
          },
          error: err => {
            this.toastr.error(err?.error?.message, 'Error', {
              timeOut: 3000,
              progressBar: true
            });
          }
        }
      )
    }
  }

}
