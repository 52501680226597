<div>
    <app-header></app-header>
    <div class="header-booking" style="gap: 10px;">
        <img src="../../../../assets/icon/back-cart.svg" alt="back-cart" (click)="redirectTo()" style="cursor: pointer;">
        <span>Booking Cart</span>
    </div>
    <div class="content">
        <div class="d-flex" style="gap: 20px;">
            <span class="cart-title col-9">Cart</span>
            <span class="cart-title col-3">Price Summary</span>

        </div>
        <app-cart [cartList]="cartList" (refreshCart)="refreshCartList($event)"></app-cart>
    </div>
    </div>
