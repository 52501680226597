import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobTitle, User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { AddNewTrainerComponent } from './add-new-trainer/add-new-trainer.component';
import { SuccessModalComponent } from '../global-modal/global-modal.component';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent {

  constructor(private apiService: ApiService, private modalService: NgbModal, private courseService: CourseService) {}
  searchTrainer: string = '';
  trainerList: User[] = [];
  trainerSearch: User[] = [];
  isGridView: boolean = false;

  jobTitle: JobTitle[] = [];
  
  ngOnInit() {
    

    this.getJobTitles();
   
  }

  getJobTitles() {
    this.courseService.getCourseTrainingCategory('job_title').subscribe(res => {
      this.jobTitle = res;
      this.getTrainerList();
    });
  }

  getTitle(id: string) {
    console.log('>> titleee', this.jobTitle, id)
    return id != null && this.jobTitle.filter(item => item._id == id).length > 0 ? this.jobTitle.filter(item => item._id == id)[0].jt_display_name : 'No Job Title'
  }

  searchFunc(evt: KeyboardEvent) {
    if (evt.keyCode === 13) {
      const searchResult = this.trainerList.filter(trainer => trainer.u_full_name.toLowerCase().includes(this.searchTrainer.toLowerCase()));
      this.trainerSearch = searchResult
    }
  }

  getTrainerList() {
    this.apiService.getTrainer('').subscribe(res => {
      console.log('>> trainersss', res)
      const updated = res.map((item: User) => ({
        ...item,
        job_title: this.getTitle(item.u_job_title!)
      }))
      this.trainerList = updated;
      this.trainerSearch = updated;
    })
  }

  navigateToAddTrainer() {
    const modalRef = this.modalService.open(AddNewTrainerComponent, {
      centered: true,
      backdrop: 'static',
    })

    modalRef.componentInstance.closeModal.subscribe(() => {
     

      const successModal = this.modalService.open(SuccessModalComponent, {
        centered: true,
        backdrop: 'static',
      })

      successModal.componentInstance.isSuccess = true;
      successModal.componentInstance.title = 'Success!!!';
      successModal.componentInstance.message = 'New trainer successfully created!';

      successModal.componentInstance.closeModal.subscribe(() => {
        this.modalService.dismissAll();
        this.getTrainerList()// Close the modal
      })
    });
  }
}
