import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClassType, CourseModule, FileCustom } from 'src/app/models/course-interface';
import { PreviewPdfComponent } from 'src/app/components/create-course/create-modules/preview-pdf/preview-pdf.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.scss']
})
export class AddModuleComponent {
  constructor(private toastr: ToastrService, private courseService: CourseService, private modalRef: NgbModal, private apiCommonService: CommonService, private formBuilder: FormBuilder) { }

  moduleForm: FormGroup = new FormGroup({
    cm_title: new FormControl(''),
    cm_description: new FormControl(''),
    cm_class_type: new FormControl(''),
    cm_duration: new FormControl(''),
  })


  submitted: boolean = false;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  modulePost: CourseModule = {
    _id: '',
    cm_title: '',
    cm_description: '',
    cm_course_id: '',
    cm_class_type: 'class_type',
    cm_duration: 30,
    cm_url: '',
    cm_access: false,
    cm_files: [],
    cm_status: false,
    cm_deletedAt: null,
    createdAt: '',
    updatedAt: '',
    cm_type: '',
    __v: 0,
    cm_allow_access: false,
    files: []
  }

  @Input() classType: ClassType[] = [];

  @Input() courseId: string = '';

  // get f(): { [key: string]: AbstractControl } {
  //   return this.moduleForm.controls;
  // }

  // get f() { return this.moduleForm.controls; }

  ngOnInit() {
    this.moduleForm = this.formBuilder.group(
      {
        cm_title: ['', [Validators.required]],
        cm_description: ['', [Validators.required]],
        cm_class_type: ['', [Validators.required]],
        cm_duration: ['', [Validators.required]], 
      },
    );

    this.moduleForm = this.formBuilder.group({
      modules: this.formBuilder.array([this.createModuleGroup()])
    });

    // this.indexAddRemove('add');

  }

  get modules(): FormArray {
    return this.moduleForm.get('modules') as FormArray;
  }

  createModuleGroup(): FormGroup {
    return this.formBuilder.group({
      cm_title: ['', Validators.required],
      cm_description: ['', Validators.required],
      cm_class_type: ['', Validators.required],
      cm_duration: ['', [Validators.required, Validators.min(1)]],
      files: [[]],
      cm_url: [''],
      cm_allow_access: [false]
    });
  }

  indexAddRemove(type: string, idx?: number) {
    if (type == 'remove') {
      this.modules.removeAt(idx!);
    } else {
      // this.module.push(JSON.parse(JSON.stringify(this.modulePost))) // to reset value instead of using the existing object
      this.modules.push(this.createModuleGroup());

    }
  }
  closeModals() {
    this.modalRef.dismissAll()
  }

  handleFileHere(event: Event, idx: number) {
    const input = event.target as HTMLInputElement;
    const files: FileList | null = input.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i], idx, this.modules.value[idx].files!.length);
      }
    }
  }

  uploadFile(file: File, idx: number, i: number) {
    const data: FileCustom = {
      progress: 0,
      fileName: file.name,
      urls: '',
      files: file
    }

    this.modules.value[idx].files!.push(data)
    
    const interval = setInterval(() => {
      this.modules.value[idx].files![i].progress += 10;
      if (this.modules.value[idx].files![i].progress === 100) {
        clearInterval(interval);
        const fileUrl = URL.createObjectURL(file);
        this.modules.value[idx].files![i].urls = fileUrl;
      }
    }, 500);
  }

  viewPdf(idx: number, i: number): void {
    const pdfUrl = this.modules.value[idx].files![i].urls;
    const pdfName = this.modules.value[idx].files![i].fileName;
    if (pdfUrl) {
      const viewPdf = this.modalRef.open(PreviewPdfComponent, {
        centered: true,
        backdrop: 'static',
        windowClass: 'pdf-modal-width'
      });

      viewPdf.componentInstance.pdfUrl = pdfUrl;
      viewPdf.componentInstance.pdfName = pdfName;

      viewPdf.componentInstance.closeModal.subscribe(() => {
        viewPdf.close();
      });
    } else {
      this.toastr.error(`No PDF URL found for module ${idx}`, 'Error', {
        timeOut: 3000,
        progressBar: true,
      });
    }
  }

  saveModule() {
    this.submitted = true;

    if (this.moduleForm.invalid) {
      return;
    }
    else {
      console.log('>> Sending createCourseModule request', this.modules);
    const promises = this.modules.value.map((module: CourseModule, idx: number) => {
      const formData = new FormData();
      formData.append('cm_course_id', this.courseId);
      formData.append('cm_title', module.cm_title);
      formData.append('cm_description', module.cm_description);
      formData.append('cm_class_type', module.cm_class_type);
      formData.append('cm_duration', module.cm_duration.toString());
      formData.append('cm_allow_access', module.cm_allow_access.toString());

      return new Promise((resolve, reject) => {
        this.courseService.createCourseModule(formData).subscribe(
          response => {
            if (this.modules.value[idx].files.length != 0) {
              const urls = this.modules.value[idx].files.map((item: any) => {
                return item.files
              }).flat()
  
              const formFile = new FormData();
              formFile.append('prefix', 'course_module');
              urls.forEach((file:any) => {
                formFile.append('files', file);
              });
              formFile.append('prefix_id', response._id);
  
              this.apiCommonService.addFiles(formFile).subscribe(
                res => {
                  resolve(res);
                },
                err => {
                  reject(err);
                }
              );
            } else {
              resolve(response);
            }
            
          },
          err => {
            reject(err);
          }
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        this.toastr.success('Successfully added modules', 'Success', {
          timeOut: 3000,
          progressBar: true,
        });
        this.modalRef.dismissAll()
        this.closeModal.emit();
      })
      .catch(() => {
        this.toastr.error('One or more requests failed', 'Error', {
          timeOut: 3000,
          progressBar: true,
        });
      });
    }



  }



}
