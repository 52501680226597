import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-list-trainer',
  templateUrl: './list-trainer.component.html',
  styleUrls: ['./list-trainer.component.scss']
})
export class ListTrainerComponent {

  constructor(private router: Router) {}

  @Input() trainerList: User[] = [];

  currentPage: number = 1;
  perPage: number = 0;
 
  returnStatusBadge(status: boolean) {
    if (status) {
      return 'user-active'
    } else {
      return 'user-inactive'
    }
  }

  get pageSize(): number {
    const perPage = 5; // Default page size
    const totalItems = this.trainerList.length;
    return totalItems > perPage ? perPage : totalItems
  }

  get totalPages(): number {
    return Math.ceil(this.trainerList.length / this.pageSize);
  }

  viewTrainerProfile(id: string) {
    this.router.navigate([`pages/profile/${id}`]);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  formatDate(date: string) {
    const inputDate = new Date(date);

    const day = inputDate.getDate().toString().padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate

  }

  formatTime(date: string) {
    const inputDate = new Date(date);

    const hours = inputDate.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = inputDate.getMinutes().toString().padStart(2, '0');
    const ampm = inputDate.getHours() >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return formattedTime
  }

}
